import React, { useContext, useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import "./PaypalBoxGuest.css";
import { SumaContext } from '../context/SumaContext';

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

function PaypalBoxGuest() {
    const [sumatotal, setSumatotal] = useContext(SumaContext);
    const [sumafinal, setSumafinal] = useState();

    useEffect(() => {
      if (sumatotal >= 100.00) {
          const presuma = (parseFloat(sumatotal)/3.8).toFixed(2);
          setSumafinal(presuma);
          
      } else {
          const presuma = ((parseFloat(sumatotal) + 5.00)/3.8).toFixed(2);
          setSumafinal(presuma);
      }
    }, [])

    const [donation, setDonation] = useState(0)
    const createOrder = (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: sumafinal
              }
            }
          ]
        });
      };

    const onApprove = (data, actions) => {
        return actions.order.capture();
    };

    return (
        <div className="paypalboxguest">
            <div className="paypalboxguest__contents">
                {/* <div className="paypalbox__contents__donation">
                    <p>Indica el monto en USD $:</p>
                    <input 
                        type="text"
                        onChange={handleChange}
                        value={donation}
                    />
                </div> */}
                <PayPalButton
                    createOrder={(data, actions) => createOrder(data, actions)}
                    onApprove={(data, actions) => onApprove(data, actions)}
                />
            </div>
        </div>
    );
}

export default PaypalBoxGuest;
