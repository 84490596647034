import React from 'react';
import { useStateValue } from '../StateProvider';
import { Link, useHistory } from "react-router-dom";
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import "./MyProductlistScreen.css";
import Nav from '../components/Nav';
/* import NavBottom from "../components/NavBottom"; */
import ProductCart from '../components/ShoppingCart/ProductCart';
import GoToTop from "../GoToTop";
import UndoSharp from "@material-ui/icons/UndoSharp";
import RedoSharp from "@material-ui/icons/RedoSharp";
import {Helmet} from "react-helmet";
import Copyright from "../components/Footer/Copyright";

function MyProductlistScreen() {
  const auth = getAuth(firebaseApp);
  const user = auth.currentUser;
  const [{carrito}] = useStateValue();
  const history = useHistory();

  const handleSignup = (e) => {
    e.preventDefault();
    history.push("/checkout")

    /* if (user) {
      history.push("/checkout")  
    } else {
      history.push("/signup")
    } */
  }

  return (
    <div>
      <Helmet>
        <title>Unishoper: compra en línea mientras te entretienes con tu serie o película favorita.</title>
        <meta 
          name='description'
          content="Plataforma de compra en línea que te permite interactuar con las prendas y accesorios que más te gustan mientras te entretienes con tu serie o película favorita."
        />
      </Helmet>

      <Nav/>  

      <div className="myproductlist__checkout">
        {
        carrito.length === 0 ? (
        <div>
        <h2 className="myproductlist__checkout__empty">Tu lista de productos está vacía.</h2>
        <p className="myproductlist__checkout__emptymsg">No tienes ningún producto en el carrito. <Link to="/home" style={{ textDecoration:"none", fontWeight:"500" }} ><span>¡Elige uno aquí!.</span></Link></p>
        </div>
        ) : (
            <div>

            <h2 className="myproductlist__checkout__title">¡Excelente lista de productos!</h2>

            <button 
            onClick={handleSignup}
            className="myproductlist__checkout__button">PAGAR MI PEDIDO</button>

            <h3 className="myproductlist__checkout__subtitle">Estos son los productos que recibirás en la comodidad de tu hogar.{/* <span>id: list646</span> */}</h3>

            <div className="myproductlist__checkout__wrap">
            {
            carrito.map((item, key) => (
                <div key={key} >
                <ProductCart
                    id={item.id}
                    productCod= {item.productCod}
                    image= {item.image}
                    productName= {item.productName}
                    category= {item.category}
                    /* brand= {item.brand} */
                    productType= {item.productType}
                    productTheme= {item.productTheme}
                    description= {item.description}
                    color= {item.color}
                    price= {item.price}
                    character= {item.character}
                    brandUrl= {item.brandUrl}
                    quantity= {item.quantity}
                />
                </div>
                ))
            }
            </div>

            <div className="myproductlist__checkout__buttons">
              <Link to="/home" style={{ textDecoration:"none" }} >
              <button className="myproductlist__checkout__button">
                <UndoSharp style={{ margin:"0 5px", alignItems:"center" }} />
                SEGUIR COMPRANDO</button>
              </Link>

              <button 
              onClick={handleSignup}
              className="myproductlist__checkout__button">PAGAR MI PEDIDO
              <RedoSharp style={{ margin:"0 5px", alignItems:"center" }} />
              </button>
            </div>

            </div>
        )
        }
        </div>
        {/* <NavBottom/> */}
        <Copyright />
        <GoToTop/>
    </div>
  )
}

export default MyProductlistScreen;