export const products = [
        {
            "id": "1",
            "productCod": "PE010010001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02.jpg?alt=media&token=ae4d7cf9-9ca1-4794-ac63-0c1af0a59ae3",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02.jpg?alt=media&token=e16507d4-3f4b-46a1-bd44-9b7a06c9284f",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02.jpg?alt=media&token=ed76a3e7-3ef0-4d47-831c-1a286514da9a",
            "character": "Luciana",
            "scene": "1",
            "timestamp": "50",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F01-adidas.png?alt=media&token=a9490aa6-ac05-4b67-a4a6-c9697d40faaf"
        },
        {
            "id": "2",
            "productCod": "PE010010002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Aqua Green",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02-AquaGreen.jpg?alt=media&token=90db92e3-88a5-41ad-ae89-adc8393ead84",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02-AquaGreen.jpg?alt=media&token=74978b1d-de2c-4515-901f-1ee9a465cb54",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02-AquaGreen.jpg?alt=media&token=6de4c450-a890-43a9-9c95-de125954f60d",
            "character": "Jorge",
            "scene": "1",
            "timestamp": "50",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F02-benetton.png?alt=media&token=9aa90a18-b195-40b2-9047-a70023684994"
        },
        {
            "id": "3",
            "productCod": "PE010010003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Beige",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02-Beige.jpg?alt=media&token=809c95c7-8128-446a-b503-896e1bed5b98",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02-Beige.jpg?alt=media&token=6266d9e2-c818-4d88-8731-7d8da4bb220e",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02-Beige.jpg?alt=media&token=4db89ccb-0532-4520-af4c-c020d7a3546d",
            "character": "Esmeralda",
            "scene": "1",
            "timestamp": "50",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F03-desigual.png?alt=media&token=9c258a34-d219-44df-ae9b-dc1469ecb432"
        },
        {
            "id": "4",
            "productCod": "PE010010004",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02-Blanco.jpg?alt=media&token=4f853848-7474-4c56-9641-f737b3f8f07e",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02-Blanco.jpg?alt=media&token=a3b6d094-9216-4cd5-9a54-9bf068a0c444",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02-Blanco.jpg?alt=media&token=8f3e8b4a-1516-4a08-979b-358570b87d07",
            "character": "Joaquín",
            "scene": "1",
            "timestamp": "50",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F04-diesel.png?alt=media&token=13f64df0-c8d0-4ece-a069-f057fe8c37d0"
        },
        {
            "id": "5",
            "productCod": "PE010010005",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Candy Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02-CandyBlue.jpg?alt=media&token=0b1ca278-51e3-4c87-8779-d770c8fd3150",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02-CandyBlue.jpg?alt=media&token=33a522e1-768c-4a20-8a02-ed21eff9d344",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02-CandyBlue.jpg?alt=media&token=62c676fa-b976-4302-a3f7-2d6eb6ec888f",
            "character": "Felix",
            "scene": "1",
            "timestamp": "50",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F05-forever.png?alt=media&token=c9cb55bd-7174-4094-b2c9-ee9250d554c4"
        },
        {
            "id": "6",
            "productCod": "PE010010006",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Dark Salmon",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02-DarkSalmon.jpg?alt=media&token=8b0a46c7-8da1-4110-9048-92a0286346a0",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02-DarkSalmon.jpg?alt=media&token=c0d5c5df-995e-4fe4-b180-e8dc53a61795",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02-DarkSalmon.jpg?alt=media&token=d4cda0ae-e057-4a13-bd9f-d740ace39f0f",
            "character": "Luciana",
            "scene": "2",
            "timestamp": "100",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F06-gap.png?alt=media&token=0dfd4aff-6db9-4dcb-9f1a-bf9be8269be8"
        },
        {
            "id": "7",
            "productCod": "PE010010007",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Lila",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02-Lila.jpg?alt=media&token=feb5c524-8bc1-4e92-96cb-ee942422cb60",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02-Lila.jpg?alt=media&token=ee01a115-6bfb-48c0-a274-5d2a3ef21e24",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02-Lila.jpg?alt=media&token=032be712-f305-40d7-9d12-2ba6c1c6b88f",
            "character": "Jorge",
            "scene": "2",
            "timestamp": "100",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F07-h%26m.png?alt=media&token=c8af0220-f8d2-496d-90ed-c334ba852999"
        },
        {
            "id": "8",
            "productCod": "PE010010008",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Superheroes",
            "alterName": "T-shirt",
            "description": "Batman + Spiderman + Superman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Pink",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before02-Rosa.jpg?alt=media&token=49fcc396-331d-4dd1-8545-f4b0c8cc0942",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man02-Rosa.jpg?alt=media&token=444e6815-35c9-4827-b022-68d475866aed",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman02-Rosa.jpg?alt=media&token=f700913f-4572-4d4d-923f-5cd17be94591",
            "character": "Esmeralda",
            "scene": "2",
            "timestamp": "100",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F08-lacoste.png?alt=media&token=578855e7-c1bc-45df-89fd-9d8e3d07ad7c"
        },
        {
            "id": "9",
            "productCod": "PE010020001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Batman",
            "alterName": "T-shirt",
            "description": "La identidad secreta de Batman es Bruce Wayne, un multimillonario magnate empresarial y filántropo dueño de Empresas Wayne en Gotham City. Después de presenciar el asesinato de sus padres, el Dr. Thomas Wayne y Martha Wayne en un violento y fallido asalto cuando era niño, juró venganza contra los criminales, un juramento moderado por el sentido de la justicia. Bruce Wayne se entrena física e intelectualmente y crea un traje inspirado en los murciélagos para combatir el crimen, con sus gadgets de combate del baticinturón y sus vehículos.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before03.jpg?alt=media&token=c743fba2-4974-4a21-8426-20fc815fb482",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man03.jpg?alt=media&token=47e1ccb5-433b-49d8-8c36-7d404a85e324",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman03.jpg?alt=media&token=2c5575d5-768a-4e13-a227-949a47312c45",
            "character": "Joaquín",
            "scene": "2",
            "timestamp": "100",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F09-levis.png?alt=media&token=b0cd6890-d428-4a76-8bff-5e63ef46c823"
        },
        {
            "id": "10",
            "productCod": "PE010030001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Batman y el Guasón",
            "alterName": "T-shirt",
            "description": "Por lo general se asocia a el Guasón a una personalidad psicópata con un humor sádico y retorcido,​ lo que le ha llevado a ser catalogado como la antítesis de Batman.​ Con la excepción de una etapa en la que mantuvo un rol de bromista simple, desde el año 1970 El Guasón ha preservado sus orígenes siniestros y ha estado involucrado en ciertos momentos decisivos de Batman,​ como el asesinato de Jason Todd y la parálisis de Barbara Gordon. Su apariencia física —caracterizada por el rostro desfigurado, la piel blanca, el cabello teñido de verde y los labios rojos— proviene de los efectos de la caída accidental del criminal en un recipiente de químicos.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before04.jpg?alt=media&token=3d96dcc1-b3be-40e7-96b8-a0391c5ac64c",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man04.jpg?alt=media&token=327d4718-eeb8-4691-b758-f7b8e8549523",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman04.jpg?alt=media&token=6095a173-440f-4d5b-9094-3cfcec5a5711",
            "character": "Felix",
            "scene": "2",
            "timestamp": "100",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F10-nike.png?alt=media&token=535efcbc-00a7-4020-80a4-48ff94fdf07f"
        },
        {
            "id": "11",
            "productCod": "PE010030002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Batman y el Guasón",
            "alterName": "T-shirt",
            "description": "Por lo general se asocia a el Guasón a una personalidad psicópata con un humor sádico y retorcido,​ lo que le ha llevado a ser catalogado como la antítesis de Batman.​ Con la excepción de una etapa en la que mantuvo un rol de bromista simple, desde el año 1970 El Guasón ha preservado sus orígenes siniestros y ha estado involucrado en ciertos momentos decisivos de Batman,​ como el asesinato de Jason Todd y la parálisis de Barbara Gordon. Su apariencia física —caracterizada por el rostro desfigurado, la piel blanca, el cabello teñido de verde y los labios rojos— proviene de los efectos de la caída accidental del criminal en un recipiente de químicos.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Sky Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before04-SkyBlue.jpg?alt=media&token=bb4b1040-6690-4f91-b2d6-a5e0db57c8e3",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man04-SkyBlue.jpg?alt=media&token=7b8df4c8-f038-4c9e-a8ac-eb2a08d5b370",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman04-SkyBlue.jpg?alt=media&token=33255d3e-e143-4453-af09-c99e8d3c5dbd",
            "character": "Luciana",
            "scene": "3",
            "timestamp": "150",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F11-mango.png?alt=media&token=c8808dfb-e09f-49aa-8026-93048a2b8e6c"
        },
        {
            "id": "12",
            "productCod": "PE010040001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05.jpg?alt=media&token=5c56f427-6f95-441c-9558-28693837053d",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05.jpg?alt=media&token=f8736c7d-c48b-4561-9493-7dbcc23e18f6",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05.jpg?alt=media&token=e3cd2a61-9025-4073-9d61-e50c6f5f2fc8",
            "character": "Jorge",
            "scene": "3",
            "timestamp": "150",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F12-tommy.png?alt=media&token=cef3ba81-92db-4e4d-a18b-29936cdabd19"
        },
        {
            "id": "13",
            "productCod": "PE010040002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Aqua Green",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05-AquaGreen.jpg?alt=media&token=cd9602bb-f715-40ac-9bf4-f34a4010ae55",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05-AquaGreen.jpg?alt=media&token=73f25559-ac30-44b5-a305-6f893acb1c02",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05-AquaGreen.jpg?alt=media&token=d746cf4a-24b8-4ab4-b350-0a99e95ce5d0",
            "character": "Esmeralda",
            "scene": "3",
            "timestamp": "150",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F13-uniqlo.png?alt=media&token=90df314d-34f5-4390-b826-ec27295a2293"
        },
        {
            "id": "14",
            "productCod": "PE010040003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05-Blanco.jpg?alt=media&token=be0c4a75-ded7-4e28-b3fa-c0b2468a938a",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05-Blanco.jpg?alt=media&token=9c00de1f-cf6d-44de-bb30-7068f42d2095",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05-Blanco.jpg?alt=media&token=0b8feca4-a6aa-4e67-9935-d2dddeb5b378",
            "character": "Joaquín",
            "scene": "3",
            "timestamp": "150",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F14-zara.png?alt=media&token=594afba5-77fe-4248-b6f2-a72ae0f5e33e"
        },
        {
            "id": "15",
            "productCod": "PE010040004",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Candy Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05-CandyBlue.jpg?alt=media&token=25105178-ef71-429e-8cfc-7c02e7bfb31e",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05-CandyBlue.jpg?alt=media&token=c20e1b5c-baa4-4960-b6b8-12dd95c3a5c1",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05-CandyBlue.jpg?alt=media&token=f0eca2e3-2e86-4878-b83e-ee0bf2f7b343",
            "character": "Felix",
            "scene": "3",
            "timestamp": "150",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F01-adidas.png?alt=media&token=a9490aa6-ac05-4b67-a4a6-c9697d40faaf"
        },
        {
            "id": "16",
            "productCod": "PE010040005",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Dark Salmon",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05-DarkSalmon.jpg?alt=media&token=5f46b2b1-2407-4da6-a787-a5eaf7a63eac",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05-DarkSalmon.jpg?alt=media&token=e83e5962-9bd9-4788-b147-d1697b7a453b",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05-DarkSalmon.jpg?alt=media&token=14887514-c64e-40bc-bc54-715c9fa7b182",
            "character": "Luciana",
            "scene": "4",
            "timestamp": "200",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F02-benetton.png?alt=media&token=9aa90a18-b195-40b2-9047-a70023684994"
        },
        {
            "id": "17",
            "productCod": "PE010040006",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Lila",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05-Lila.jpg?alt=media&token=aa1f9bad-340c-449f-9ecb-5d2a24ed62a1",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05-Lila.jpg?alt=media&token=ecaec7d1-6bbc-4d03-b8fe-a97278858145",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05-Lila.jpg?alt=media&token=092da690-a17a-4f72-9468-1ff39fc128f2",
            "character": "Jorge",
            "scene": "4",
            "timestamp": "200",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F03-desigual.png?alt=media&token=9c258a34-d219-44df-ae9b-dc1469ecb432"
        },
        {
            "id": "18",
            "productCod": "PE010040007",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Pink",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05-Rosa.jpg?alt=media&token=fc75f4e2-c4b5-4643-b163-e19cc1b2fb85",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05-Rosa.jpg?alt=media&token=57f4ae71-ab26-40d6-b621-56a15bebd5c7",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05-Rosa.jpg?alt=media&token=b03db0d0-1110-4630-94db-72da0c7826db",
            "character": "Esmeralda",
            "scene": "4",
            "timestamp": "200",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F04-diesel.png?alt=media&token=13f64df0-c8d0-4ece-a069-f057fe8c37d0"
        },
        {
            "id": "19",
            "productCod": "PE010040008",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Chucky",
            "alterName": "T-shirt",
            "description": "Chucky es un personaje ficticio de la saga de películas de terror Child's Play creado por Don Mancin. Chucky es descrito como un muñeco Good Guy que fue poseído por medio de magia vudú por el asesino en serie Charles Lee Ray. En la versión del 2019, es un inofensivo muñeco robot Buddi que se vuelve psicópata y asesino tras sufrir un fallo en el sistema.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Sky Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before05-SkyBlue.jpg?alt=media&token=f5d968ec-e604-4aa5-9644-0433a0c6f3bd",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man05-SkyBlue.jpg?alt=media&token=1ddfc060-2f03-4dfe-ab19-b3ec829c526d",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman05-SkyBlue.jpg?alt=media&token=afc9712c-7cf4-424d-a417-fa4f3ee465b0",
            "character": "Joaquín",
            "scene": "4",
            "timestamp": "200",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F05-forever.png?alt=media&token=c9cb55bd-7174-4094-b2c9-ee9250d554c4"
        },
        {
            "id": "20",
            "productCod": "PE010050001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06.jpg?alt=media&token=84df352f-0573-42c7-a113-37bfd529aab6",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06.jpg?alt=media&token=59f5a404-d0cd-4a1e-a808-dcfb532349db",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06.jpg?alt=media&token=77cb07d9-4ed8-42ce-a77a-0219815add93",
            "character": "Felix",
            "scene": "4",
            "timestamp": "200",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F06-gap.png?alt=media&token=0dfd4aff-6db9-4dcb-9f1a-bf9be8269be8"
        },
        {
            "id": "21",
            "productCod": "PE010050002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Aqua Green",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06-AquaGreen.jpg?alt=media&token=a2e4edd8-25c6-4bdb-9e2f-7752d72171e0",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06-AquaGreen.jpg?alt=media&token=cf8d6bc8-7b15-42c3-a97e-2eea00c6be22",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06-AquaGreen.jpg?alt=media&token=fd71354a-4f18-4373-8544-1c54f6369e2b",
            "character": "Luciana",
            "scene": "5",
            "timestamp": "250",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F07-h%26m.png?alt=media&token=c8af0220-f8d2-496d-90ed-c334ba852999"
        },
        {
            "id": "22",
            "productCod": "PE010050003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Beige",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06-Beige.jpg?alt=media&token=e1dc77f0-9d70-4422-a7fa-f8561c4939c0",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06-Beige.jpg?alt=media&token=d3f337b0-2713-4923-91e2-f7f1301a8b55",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06-Beige.jpg?alt=media&token=3d7b006f-c4c5-4bf8-aee3-1b2dcd4e19f9",
            "character": "Jorge",
            "scene": "5",
            "timestamp": "250",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F08-lacoste.png?alt=media&token=578855e7-c1bc-45df-89fd-9d8e3d07ad7c"
        },
        {
            "id": "23",
            "productCod": "PE010050004",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06-Blanco.jpg?alt=media&token=340fbe2f-9199-4726-a377-a2682068fa7b",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06-Blanco.jpg?alt=media&token=718c762e-e6db-45ff-bb4c-f92693746f2e",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06-Blanco.jpg?alt=media&token=5f828994-a90d-48dc-a01a-1451377a447e",
            "character": "Esmeralda",
            "scene": "5",
            "timestamp": "250",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F09-levis.png?alt=media&token=b0cd6890-d428-4a76-8bff-5e63ef46c823"
        },
        {
            "id": "24",
            "productCod": "PE010050005",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Candy Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06-CandyBlue.jpg?alt=media&token=0ca34d96-b93c-4de9-8284-7b4318be70ad",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06-CandyBlue.jpg?alt=media&token=7d5cc263-ca29-4d0e-9462-d347cf693cae",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06-CandyBlue.jpg?alt=media&token=fa7e93ba-5d52-42de-a39a-fd6cd3cb5124",
            "character": "Joaquín",
            "scene": "5",
            "timestamp": "250",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F10-nike.png?alt=media&token=535efcbc-00a7-4020-80a4-48ff94fdf07f"
        },
        {
            "id": "25",
            "productCod": "PE010050006",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Pink",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06-Rosa.jpg?alt=media&token=3fa29512-7b61-4486-a643-5ad6f81ce446",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06-Rosa.jpg?alt=media&token=9480de2c-1538-4173-9b86-16094bead85f",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06-Rosa.jpg?alt=media&token=11dd430c-f861-406d-bb4e-40957691df0d",
            "character": "Felix",
            "scene": "5",
            "timestamp": "250",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F11-mango.png?alt=media&token=c8808dfb-e09f-49aa-8026-93048a2b8e6c"
        },
        {
            "id": "26",
            "productCod": "PE010050007",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Royal Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06-RoyalBlue.jpg?alt=media&token=6cea0f6d-b56e-4925-aa61-041b17ef220e",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06-RoyalBlue.jpg?alt=media&token=73c5d061-c132-4b37-935b-380d6b51b5fd",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06-RoyalBlue.jpg?alt=media&token=a7c3cffb-f86f-4e43-8bd8-c57d4cb0cf3b",
            "character": "Luciana",
            "scene": "6",
            "timestamp": "300",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F12-tommy.png?alt=media&token=cef3ba81-92db-4e4d-a18b-29936cdabd19"
        },
        {
            "id": "27",
            "productCod": "PE010050008",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Sky Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before06-SkyBlue.jpg?alt=media&token=ce44340f-9af7-4cd1-a2df-679ec4bcf97f",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man06-SkyBlue.jpg?alt=media&token=3ed4707f-968e-45cf-a796-81f8d6afaddc",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman06-SkyBlue.jpg?alt=media&token=d4a3f447-ffe2-4550-9e8d-96c7280c4e68",
            "character": "Jorge",
            "scene": "6",
            "timestamp": "300",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F13-uniqlo.png?alt=media&token=90df314d-34f5-4390-b826-ec27295a2293"
        },
        {
            "id": "28",
            "productCod": "PE010060001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly cinematic - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before07.jpg?alt=media&token=55c94ed3-2ea9-4c0d-8387-a12c58abbf0f",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man07.jpg?alt=media&token=53138fa2-2f08-4f29-a3f7-4f4040609d7d",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman07.jpg?alt=media&token=493c331b-6b85-4f8b-81eb-9e0a8f291b74",
            "character": "Esmeralda",
            "scene": "6",
            "timestamp": "300",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F14-zara.png?alt=media&token=594afba5-77fe-4248-b6f2-a72ae0f5e33e"
        },
        {
            "id": "29",
            "productCod": "PE010060002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly cinematic - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Aqua Green",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before07-AquaGreen.jpg?alt=media&token=4a5872f8-d593-476e-9e80-6fa3db9daf14",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man07-AquaGreen.jpg?alt=media&token=c518e7a5-8ef6-43a7-ba14-2be659e12ef2",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman07-AquaGreen.jpg?alt=media&token=84768bab-926c-4381-9e97-4f1409266c3e",
            "character": "Joaquín",
            "scene": "6",
            "timestamp": "300",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F01-adidas.png?alt=media&token=a9490aa6-ac05-4b67-a4a6-c9697d40faaf"
        },
        {
            "id": "30",
            "productCod": "PE010060003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly cinematic - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before07-Blanco.jpg?alt=media&token=0c98f96b-348c-41e6-928d-8181dbb44077",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man07-Blanco.jpg?alt=media&token=37334dd0-9f4e-4e0c-a2b2-0169fd024ef5",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman07-Blanco.jpg?alt=media&token=43f0ffab-b08d-4bfa-bbb5-591ab4a31c02",
            "character": "Felix",
            "scene": "6",
            "timestamp": "300",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F02-benetton.png?alt=media&token=9aa90a18-b195-40b2-9047-a70023684994"
        },
        {
            "id": "31",
            "productCod": "PE010060004",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly cinematic - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Pink",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before07-Rosa.jpg?alt=media&token=35435076-7abb-4458-8891-78923c75166b",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man07-Rosa.jpg?alt=media&token=a9559a4d-4de5-4c21-b69c-e207d521a4ac",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman07-Rosa.jpg?alt=media&token=c30e25c7-ccd2-43c9-9b41-2de2000c1df2",
            "character": "Luciana",
            "scene": "7",
            "timestamp": "350",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F03-desigual.png?alt=media&token=9c258a34-d219-44df-ae9b-dc1469ecb432"
        },
        {
            "id": "32",
            "productCod": "PE010070001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly victory - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before08.jpg?alt=media&token=984084ea-f007-4c03-8d5e-a8fec5acd441",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man08.jpg?alt=media&token=dbe96e2b-7ea6-4466-87e2-af323588f07c",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman08.jpg?alt=media&token=aa57aa11-0360-4a14-a123-37e611e0cd95",
            "character": "Jorge",
            "scene": "7",
            "timestamp": "350",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F04-diesel.png?alt=media&token=13f64df0-c8d0-4ece-a069-f057fe8c37d0"
        },
        {
            "id": "33",
            "productCod": "PE010070002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly victory - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before08-Blanco.jpg?alt=media&token=4c349b27-9720-412e-a017-97481cb9c145",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man08-Blanco.jpg?alt=media&token=0dd7bea6-e0f0-44a5-b29e-7d3205823b74",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman08-Blanco.jpg?alt=media&token=67355395-c1d3-4149-a196-63e6d34a3927",
            "character": "Esmeralda",
            "scene": "7",
            "timestamp": "350",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F05-forever.png?alt=media&token=c9cb55bd-7174-4094-b2c9-ee9250d554c4"
        },
        {
            "id": "34",
            "productCod": "PE010070003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Daly victory - Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Pink",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before08-Rosa.jpg?alt=media&token=f061c4ae-5a5a-4b35-823d-1bbd9541b1e3",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man08-Rosa.jpg?alt=media&token=d1c27aec-4ef5-4387-b9ae-a96207041a65",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman08-Rosa.jpg?alt=media&token=4f9c3f7a-fd85-40b0-9fae-a3c3d24ad8d9",
            "character": "Joaquín",
            "scene": "7",
            "timestamp": "350",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F06-gap.png?alt=media&token=0dfd4aff-6db9-4dcb-9f1a-bf9be8269be8"
        },
        {
            "id": "35",
            "productCod": "PE010080001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "IT Clown",
            "alterName": "T-shirt",
            "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before09.jpg?alt=media&token=9ac8bbae-4300-49b0-ace2-1fab2e4853f0",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man09.jpg?alt=media&token=37943e6f-066d-4833-b747-3f0d9e4a7ec1",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman09.jpg?alt=media&token=f248773b-cff7-4545-ae99-1ce8ca35618d",
            "character": "Felix",
            "scene": "7",
            "timestamp": "350",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F07-h%26m.png?alt=media&token=c8af0220-f8d2-496d-90ed-c334ba852999"
        },
        {
            "id": "36",
            "productCod": "PE010080002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "IT Clown",
            "alterName": "T-shirt",
            "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Beige",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before09-Beige.jpg?alt=media&token=a0a3287b-1030-4b6e-a7e4-156bcd55b52e",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man09-Beige.jpg?alt=media&token=d48965f2-ef38-449f-a287-3c11973a9083",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman09-Beige.jpg?alt=media&token=66b3d9cf-53b5-41a2-999f-c666cc532e74",
            "character": "Luciana",
            "scene": "8",
            "timestamp": "400",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F08-lacoste.png?alt=media&token=578855e7-c1bc-45df-89fd-9d8e3d07ad7c"
        },
        {
            "id": "37",
            "productCod": "PE010080003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "IT Clown",
            "alterName": "T-shirt",
            "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before09-Blanco.jpg?alt=media&token=ae389f8a-11e6-441f-9bd0-80cd19f07a79",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man09-Blanco.jpg?alt=media&token=1ee8997b-6b6b-4dae-b1e7-4553da64d1c3",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman09-Blanco.jpg?alt=media&token=690a7496-7e07-4790-889d-ba8e30707475",
            "character": "Jorge",
            "scene": "8",
            "timestamp": "400",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F09-levis.png?alt=media&token=b0cd6890-d428-4a76-8bff-5e63ef46c823"
        },
        {
            "id": "38",
            "productCod": "PE010080004",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "IT Clown",
            "alterName": "T-shirt",
            "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Candy Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before09-CandyBlue.jpg?alt=media&token=9b0e7697-bb5b-4800-9131-92de00458c02",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man09-CandyBlue.jpg?alt=media&token=32c371fb-79c3-428d-a744-ef8ec8aa2263",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman09-CandyBlue.jpg?alt=media&token=4b9620c2-5790-4b6a-ba6c-f10d755f955d",
            "character": "Esmeralda",
            "scene": "8",
            "timestamp": "400",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F10-nike.png?alt=media&token=535efcbc-00a7-4020-80a4-48ff94fdf07f"
        },
        {
            "id": "39",
            "productCod": "PE010080005",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "IT Clown",
            "alterName": "T-shirt",
            "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Pink",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before09-Rosa.jpg?alt=media&token=2b888eb5-5a75-4f4b-88ea-741c990ac714",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man09-Rosa.jpg?alt=media&token=f1435c9b-5689-474a-a8ee-b9906b9bd7a9",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman09-Rosa.jpg?alt=media&token=f5d42e76-c673-4896-9d94-eba246c9303a",
            "character": "Joaquín",
            "scene": "8",
            "timestamp": "400",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F11-mango.png?alt=media&token=c8808dfb-e09f-49aa-8026-93048a2b8e6c"
        },
        {
            "id": "40",
            "productCod": "PE010080006",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "IT Clown",
            "alterName": "T-shirt",
            "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Royal Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before09-RoyalBlue.jpg?alt=media&token=291483e2-9e2c-481a-b1ab-a55c82aee508",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man09-RoyalBlue.jpg?alt=media&token=eef9241d-28bc-4341-bf65-7fcc642d05dd",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman09-RoyalBlue.jpg?alt=media&token=0c7e62d6-b12d-4235-97e7-2fa402767c85",
            "character": "Felix",
            "scene": "8",
            "timestamp": "400",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F12-tommy.png?alt=media&token=cef3ba81-92db-4e4d-a18b-29936cdabd19"
        },
        {
            "id": "41",
            "productCod": "PE010080007",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "IT Clown",
            "alterName": "T-shirt",
            "description": "Pennywise, el payaso bailarín, también llamado Eso (en inglés, It), es un personaje ficticio. Varios niños de una pequeña ciudad del estado de Maine se alían para combatir a una entidad diabólica que adopta la forma de un payaso y desde hace mucho tiempo emerge cada 27 años para saciarse de sangre infantil.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Sky Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before09-SkyBlue.jpg?alt=media&token=4da6760c-3312-4096-8d2b-2a8e58a30fe3",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man09-SkyBlue.jpg?alt=media&token=eda1b03a-d3a7-408c-9ce1-7442250ecbcf",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman09-SkyBlue.jpg?alt=media&token=248f4fb3-1435-4a69-9b41-78059e50d334",
            "character": "Luciana",
            "scene": "9",
            "timestamp": "450",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F13-uniqlo.png?alt=media&token=90df314d-34f5-4390-b826-ec27295a2293"
        },
        {
            "id": "42",
            "productCod": "PE010090001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "The Purge - mask",
            "alterName": "T-shirt",
            "description": "Año 2022. En una futura sociedad despótica, el régimen político, llamado Nueva Fundación de los padres de América, ha decidido implantar una medida catártica: la purga anual, según la cual una noche al año se puede cometer cualquier clase de crimen, sin tener que responder ante la justicia.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before11.jpg?alt=media&token=2802502d-ab34-4600-b691-70b27190aec4",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man11.jpg?alt=media&token=5078fae4-fbef-498f-8b71-755e54c1df46",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman11.jpg?alt=media&token=563d5ca6-e046-485b-a345-cc9085e0d967",
            "character": "Jorge",
            "scene": "9",
            "timestamp": "450",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F14-zara.png?alt=media&token=594afba5-77fe-4248-b6f2-a72ae0f5e33e"
        },
        {
            "id": "43",
            "productCod": "PE010100001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "The Purge - blue mask",
            "alterName": "T-shirt",
            "description": "Año 2022. En una futura sociedad despótica, el régimen político, llamado Nueva Fundación de los padres de América, ha decidido implantar una medida catártica: la purga anual, según la cual una noche al año se puede cometer cualquier clase de crimen, sin tener que responder ante la justicia.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before12.jpg?alt=media&token=33db1904-0875-4373-bec6-80654cb22ee0",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man12.jpg?alt=media&token=ec59c1e3-79be-4335-89c1-ca73e65fa8da",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman12.jpg?alt=media&token=58e84b96-f088-49e0-a612-48956804084d",
            "character": "Esmeralda",
            "scene": "9",
            "timestamp": "450",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F01-adidas.png?alt=media&token=a9490aa6-ac05-4b67-a4a6-c9697d40faaf"
        },
        {
            "id": "44",
            "productCod": "PE010110001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "The Purge - red",
            "alterName": "T-shirt",
            "description": "Año 2022. En una futura sociedad despótica, el régimen político, llamado Nueva Fundación de los padres de América, ha decidido implantar una medida catártica: la purga anual, según la cual una noche al año se puede cometer cualquier clase de crimen, sin tener que responder ante la justicia.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before13.jpg?alt=media&token=59ff050a-8c57-4b4c-bba1-e592033f22e6",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man13.jpg?alt=media&token=ff537c19-7b34-4595-8950-d333724441a5",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman13.jpg?alt=media&token=5140e4fc-c46d-4988-a9d5-618e3a9b6a08",
            "character": "Joaquín",
            "scene": "9",
            "timestamp": "450",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F02-benetton.png?alt=media&token=9aa90a18-b195-40b2-9047-a70023684994"
        },
        {
            "id": "45",
            "productCod": "PE010120001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14.jpg?alt=media&token=f436d281-23b4-459d-b28d-fa08a21abf0c",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14.jpg?alt=media&token=8b98be85-631b-4927-a73c-d8338692e067",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14.jpg?alt=media&token=6d2f2509-9bc2-4126-9819-edfbdc49c0c4",
            "character": "Felix",
            "scene": "9",
            "timestamp": "450",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F03-desigual.png?alt=media&token=9c258a34-d219-44df-ae9b-dc1469ecb432"
        },
        {
            "id": "46",
            "productCod": "PE010120002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Beige",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14-Beige.jpg?alt=media&token=1c76a1de-2ff8-4d90-b8a6-c46f84006b6b",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14-Beige.jpg?alt=media&token=e1039353-0b94-412d-a99f-434cd8af43de",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14-Beige.jpg?alt=media&token=030cb36b-3cdc-4265-9200-8d1cf00c3a33",
            "character": "Luciana",
            "scene": "10",
            "timestamp": "500",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F04-diesel.png?alt=media&token=13f64df0-c8d0-4ece-a069-f057fe8c37d0"
        },
        {
            "id": "47",
            "productCod": "PE010120003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14-Blanco.jpg?alt=media&token=56f0c2d9-57f1-402e-81f2-3e049e154f5f",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14-Blanco.jpg?alt=media&token=79950fc0-402c-44fe-b0f6-ec03bf44d9b4",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14-Blanco.jpg?alt=media&token=487283ab-303f-4e62-90ba-4f344d495022",
            "character": "Jorge",
            "scene": "10",
            "timestamp": "500",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F05-forever.png?alt=media&token=c9cb55bd-7174-4094-b2c9-ee9250d554c4"
        },
        {
            "id": "48",
            "productCod": "PE010120004",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Candy Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14-CandyBlue.jpg?alt=media&token=2dbc7e54-b4ed-452e-a125-3ef6ff05ab9a",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14-CandyBlue.jpg?alt=media&token=6e59e2ea-eeeb-44a4-a00d-e65972fb9e51",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14-CandyBlue.jpg?alt=media&token=956b7cb8-6d8b-4178-b6c5-8d89a38f731b",
            "character": "Esmeralda",
            "scene": "10",
            "timestamp": "500",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F06-gap.png?alt=media&token=0dfd4aff-6db9-4dcb-9f1a-bf9be8269be8"
        },
        {
            "id": "49",
            "productCod": "PE010120005",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Dark Salmon",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14-DarkSalmon.jpg?alt=media&token=0abcf2fe-038f-4dde-b59e-4695987d9f5c",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14-DarkSalmon.jpg?alt=media&token=81a1601a-f198-4dbb-89f9-3f28da93e4de",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14-DarkSalmon.jpg?alt=media&token=f9c25cfb-a4ee-46d8-9cba-0b6bb10ae165",
            "character": "Joaquín",
            "scene": "10",
            "timestamp": "500",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F07-h%26m.png?alt=media&token=c8af0220-f8d2-496d-90ed-c334ba852999"
        },
        {
            "id": "50",
            "productCod": "PE010120006",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Pink",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14-Rosa.jpg?alt=media&token=3ccce2b0-4422-4a3d-8b85-65850a21d7cb",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14-Rosa.jpg?alt=media&token=61af0b61-67fc-4fa7-a528-c7bf010b91c1",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14-Rosa.jpg?alt=media&token=099b70e4-16e4-4ddd-9797-8dd6c619902d",
            "character": "Felix",
            "scene": "10",
            "timestamp": "500",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F08-lacoste.png?alt=media&token=578855e7-c1bc-45df-89fd-9d8e3d07ad7c"
        },
        {
            "id": "51",
            "productCod": "PE010120007",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Royal Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14-RoyalBlue.jpg?alt=media&token=a66f52de-f7cb-47dd-b618-8f089cfd6362",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14-RoyalBlue.jpg?alt=media&token=9b89faa2-d82a-4485-9952-5f5388a9b05e",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14-RoyalBlue.jpg?alt=media&token=6604431a-c282-46d4-b8bc-d3b6bd5767b3",
            "character": "Luciana",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F09-levis.png?alt=media&token=b0cd6890-d428-4a76-8bff-5e63ef46c823"
        },
        {
            "id": "52",
            "productCod": "PE010120008",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - icon",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Sky Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before14-SkyBlue.jpg?alt=media&token=11f03307-a0cb-4bd8-96d7-2559f6e5741f",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man14-SkyBlue.jpg?alt=media&token=5b47071a-a8a9-41ec-a615-2df095a7dab9",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman14-SkyBlue.jpg?alt=media&token=f3532cc2-9e51-417f-ba3f-c72cbb83b694",
            "character": "Jorge",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F10-nike.png?alt=media&token=535efcbc-00a7-4020-80a4-48ff94fdf07f"
        },
        {
            "id": "53",
            "productCod": "PE010130001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - dance",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before15.jpg?alt=media&token=deb778b0-fdc8-4378-8d78-43f38b6dc27d",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man15.jpg?alt=media&token=b913c86a-8436-473e-baf9-9da39e706c33",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman15.jpg?alt=media&token=6e22e12a-899f-4409-bd0e-574d75a602e6",
            "character": "Esmeralda",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F11-mango.png?alt=media&token=c8808dfb-e09f-49aa-8026-93048a2b8e6c"
        },
        {
            "id": "54",
            "productCod": "PE010130002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - dance",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Royal Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before15-RoyalBlue.jpg?alt=media&token=518cf327-1aa4-40a8-8bf6-566cc0bb4ba7",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man15-RoyalBlue.jpg?alt=media&token=c6818bb2-c923-46e0-949d-2bd27e258c5a",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman15-RoyalBlue.jpg?alt=media&token=9c255c76-b2d5-442b-a6d8-fbd4170c708a",
            "character": "Joaquín",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F12-tommy.png?alt=media&token=cef3ba81-92db-4e4d-a18b-29936cdabd19"
        },
        {
            "id": "55",
            "productCod": "PE010140001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - character",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before16.jpg?alt=media&token=6d9cd285-a808-4032-a769-f6fa2ae52346",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man16.jpg?alt=media&token=1aed9ab5-0d6b-4523-9e25-961addf3e54c",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman16.jpg?alt=media&token=2e84182b-a42b-44ae-9cac-5b191a4b7816",
            "character": "Felix",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F13-uniqlo.png?alt=media&token=90df314d-34f5-4390-b826-ec27295a2293"
        },
        {
            "id": "56",
            "productCod": "PE010140002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - character",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before16-Blanco.jpg?alt=media&token=f711503f-bfd5-4907-a6c5-f703bfc92803",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man16-Blanco.jpg?alt=media&token=60fa4f53-d637-4937-82f1-3d3fd02b33f2",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman16-Blanco.jpg?alt=media&token=d8f71e38-7e95-4891-84a8-60d57ea4ab1c",
            "character": "Luciana",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F14-zara.png?alt=media&token=594afba5-77fe-4248-b6f2-a72ae0f5e33e"
        },
        {
            "id": "57",
            "productCod": "PE010140003",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - character",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Dark Salmon",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before16-DarkSalmon.jpg?alt=media&token=9bfa7a7f-2a8d-4738-8b4e-949d9f328359",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man16-DarkSalmon.jpg?alt=media&token=cfd5859c-0695-4cc6-b862-5d492b11f49f",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman16-DarkSalmon.jpg?alt=media&token=27205092-22c1-4544-b0e6-e9c62cd25de0",
            "character": "Jorge",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F01-adidas.png?alt=media&token=a9490aa6-ac05-4b67-a4a6-c9697d40faaf"
        },
        {
            "id": "58",
            "productCod": "PE010140004",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - character",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Royal Blue",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before16-RoyalBlue.jpg?alt=media&token=cd67eae9-743c-44d0-abb3-44eb740380b9",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man16-RoyalBlue.jpg?alt=media&token=34a1f010-ae90-4b3f-885d-0df20d2b661d",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman16-RoyalBlue.jpg?alt=media&token=eceec80e-0c9c-4593-9b96-dca07565337b",
            "character": "Esmeralda",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F02-benetton.png?alt=media&token=9aa90a18-b195-40b2-9047-a70023684994"
        },
        {
            "id": "59",
            "productCod": "PE020150001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Better Call Saul",
            "alterName": "T-shirt",
            "description": "Precuela de la serie \"Breaking Bad\", centrada en el personaje del abogado Saul Goodman (Bob Odenkirk), seis años antes de conocer a Walter White. La serie cuenta cómo un picapleitos de poca monta llamado Jimmy McGill, con problemas para llegar a fin de mes, se convierte en el abogado criminalista Saul Goodman.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before17.jpg?alt=media&token=f55b6813-4fe4-427c-9ca8-5498fea7980b",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man17.jpg?alt=media&token=a45130cd-f529-4cc9-86de-1e0e2eb52c1f",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman17.jpg?alt=media&token=0a584f04-c644-4777-bf38-ba6b390f09c1",
            "character": "Joaquín",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F03-desigual.png?alt=media&token=9c258a34-d219-44df-ae9b-dc1469ecb432"
        },
        {
            "id": "60",
            "productCod": "PE020160001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Boba Fett",
            "alterName": "T-shirt",
            "description": "El Libro de Boba Fett, una emocionante aventura de Star Wars, encuentra al legendario cazarrecompensas Boba Fett y a la mercenaria Fennec Shand navegando por el inframundo de la galaxia cuando regresan a las arenas de Tatooine para reclamar el territorio que una vez gobernó Jabba el Hutt y su sindicato criminal.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before18.jpg?alt=media&token=af855fe9-6fd9-4565-9a6b-3ac8c1f48326",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man18.jpg?alt=media&token=7a213dfa-e238-4400-8887-fef13a32fc68",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman18.jpg?alt=media&token=b0594415-d3a1-4845-b3dc-3462f4e5987a",
            "character": "Felix",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F04-diesel.png?alt=media&token=13f64df0-c8d0-4ece-a069-f057fe8c37d0"
        },
        {
            "id": "61",
            "productCod": "PE020170001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Money Heist",
            "alterName": "T-shirt",
            "description": "Money Heist o La Casa de Papel: Una banda organizada de ladrones se propone cometer el atraco del siglo en la Fábrica Nacional de Moneda y Timbre. Cinco meses de preparación quedarán reducidos a once días para poder llevar a cabo con éxito el gran golpe.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Beige",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before19-Beige.jpg?alt=media&token=153b2ad7-63ce-4826-ae4b-101010bbcb26",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man19-Beige.jpg?alt=media&token=3bad8807-6349-483c-9593-81d54f852bce",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman19-Beige.jpg?alt=media&token=bc7acfe2-3e04-401e-b71d-3554c162c0a8",
            "character": "Luciana",
            "scene": "11",
            "timestamp": "550",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F05-forever.png?alt=media&token=c9cb55bd-7174-4094-b2c9-ee9250d554c4"
        },
        {
            "id": "62",
            "productCod": "PE020180001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Love Death + Robots",
            "alterName": "T-shirt",
            "description": "Cuando una misión de exploración a una luna de Júpiter termina en desastre, la única superviviente debe emprender un viaje tan peligroso como alucinante.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before20.jpg?alt=media&token=4207a9ce-737f-45d0-98d2-e96ac4ffd8a8",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man20.jpg?alt=media&token=46397bdc-4c8c-4e71-8849-39131419f225",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman20.jpg?alt=media&token=594ee9a4-3af0-416e-b963-2f733d639ec0",
            "character": "Jorge",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F06-gap.png?alt=media&token=0dfd4aff-6db9-4dcb-9f1a-bf9be8269be8"
        },
        {
            "id": "63",
            "productCod": "PE020190001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "The Mandalorian - Baby Yoda",
            "alterName": "T-shirt",
            "description": "The Mandalorian tiene lugar después de la caída del Imperio y antes de la aparición de la Primera Orden, cinco años después de los acontecimientos narrados en Return of the Jedi y sigue a un pistolero solitario en los confines de la galaxia, lejos de la autoridad de la Nueva República.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before21.jpg?alt=media&token=bcc44271-cb97-46b5-9a7a-2498232c9860",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man21.jpg?alt=media&token=19c07e68-0dc1-42fc-a967-eb33555aa3bf",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman21.jpg?alt=media&token=76f12456-5a85-4d94-bb64-2e257bced7ef",
            "character": "Esmeralda",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F07-h%26m.png?alt=media&token=c8af0220-f8d2-496d-90ed-c334ba852999"
        },
        {
            "id": "64",
            "productCod": "PE020190002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "The Mandalorian - This is the way",
            "alterName": "T-shirt",
            "description": "The Mandalorian tiene lugar después de la caída del Imperio y antes de la aparición de la Primera Orden, cinco años después de los acontecimientos narrados en Return of the Jedi y sigue a un pistolero solitario en los confines de la galaxia, lejos de la autoridad de la Nueva República.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before22.jpg?alt=media&token=2f6d9806-7c3b-4ad7-9320-62bcfe6244aa",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man22.jpg?alt=media&token=3499b36b-e429-4217-af42-1c1e4f324763",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman22.jpg?alt=media&token=241327e1-0146-4ef9-806c-9efca3eeee25",
            "character": "Joaquín",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F08-lacoste.png?alt=media&token=578855e7-c1bc-45df-89fd-9d8e3d07ad7c"
        },
        {
            "id": "65",
            "productCod": "PE020200001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Star Wars",
            "alterName": "T-shirt",
            "description": "Star Wars relata las vivencias de la familia Skywalker, hace mucho tiempo en una galaxia muy muy lejana, cuyos integrantes son capaces de percibir y utilizar La Fuerza, lo cual les permite desarrollar habilidades como la telequinesis, la clarividencia y el control mental, entre otras.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before23-Blanco.jpg?alt=media&token=aa5b33d3-b332-4406-88fd-03ff2871fb30",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man23-Blanco.jpg?alt=media&token=04d1fbad-4293-4c5a-9559-e0a124c23e70",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman23-Blanco.jpg?alt=media&token=32997d5b-a8b4-4602-a1fd-d5070e9eb3a4",
            "character": "Felix",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "true",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F09-levis.png?alt=media&token=b0cd6890-d428-4a76-8bff-5e63ef46c823"
        },
        {
            "id": "66",
            "productCod": "PE020210001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "The Offer - Godfather",
            "alterName": "T-shirt",
            "description": "Las experiencias, nunca antes reveladas, del productor ganador del Oscar Albert S. Ruddy al producir la mítica película El padrino.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before24-Blanco.jpg?alt=media&token=e13f13ef-03b3-472e-beda-28b0aeed043d",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man24-Blanco.jpg?alt=media&token=201875d5-b919-43f0-b67c-35a35c35f51d",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman24-Blanco.jpg?alt=media&token=490dc17b-8158-4f3a-9872-aa0931b0c97b",
            "character": "Luciana",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F10-nike.png?alt=media&token=535efcbc-00a7-4020-80a4-48ff94fdf07f"
        },
        {
            "id": "67",
            "productCod": "PE020220001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Squid game - poster",
            "alterName": "T-shirt",
            "description": "La serie narra la historia de 456 personas que deciden convertirse en jugadores de una serie de misteriosos y enfermizos juegos infantiles de supervivencia mortal para tener la oportunidad de ganar la cantidad máxima de 45 600 millones de wones. Por cada persona que muere se añade 100 millones de wones al bote, incitando el conflicto entre los jugadores.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Beige",
            "size": "S, M, L, XL",
            "price": "59",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before25-Beige.jpg?alt=media&token=4be3d14d-431a-428f-aa68-6981f2b19110",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-Man25-Beige.jpg?alt=media&token=3be9d0ac-5aaa-45d0-8395-9cbfa19cfec3",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman25-Beige.jpg?alt=media&token=9b74733b-91ba-4207-bdd3-c8a1132120f3",
            "character": "Jorge",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F11-mango.png?alt=media&token=c8808dfb-e09f-49aa-8026-93048a2b8e6c"
        },
        {
            "id": "68",
            "productCod": "PE020230001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "WandaVision - TV",
            "alterName": "T-shirt",
            "description": "WandaVision combina el estilo cómico de situaciones clásicas con el Universo Cinematográfico de Marvel en el que Wanda Maximoff y Visión, dos seres superpoderosos que viven su vida suburbana ideal, comienzan a sospechar que no todo es lo que parece.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before26.jpg?alt=media&token=06a247b9-d1be-487a-bd2a-567b29854e3d",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man26.jpg?alt=media&token=7f9bdcce-9e64-4a1f-8c6a-116acf34ce7d",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman26.jpg?alt=media&token=c1870436-049b-4481-978d-453ee54e86ba",
            "character": "Esmeralda",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F12-tommy.png?alt=media&token=cef3ba81-92db-4e4d-a18b-29936cdabd19"
        },
        {
            "id": "69",
            "productCod": "PE020230002",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "WandaVision - Cinematic",
            "alterName": "T-shirt",
            "description": "WandaVision combina el estilo cómico de situaciones clásicas con el Universo Cinematográfico de Marvel en el que Wanda Maximoff y Visión, dos seres superpoderosos que viven su vida suburbana ideal, comienzan a sospechar que no todo es lo que parece.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "Black",
            "size": "XS, S, M, L, XL, XXL",
            "price": "69",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before27.jpg?alt=media&token=d6ab075d-edb8-45fa-9b55-ae06cfaee3d7",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man27.jpg?alt=media&token=ae7e7cff-4f1a-4854-be97-43b7085b6940",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman27.jpg?alt=media&token=a6ca0879-23d2-40dd-8ea8-6f776f7113ee",
            "character": "Joaquín",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F13-uniqlo.png?alt=media&token=90df314d-34f5-4390-b826-ec27295a2293"
        },
        {
            "id": "70",
            "productCod": "PE020240001",
            "category": "Polos",
            "gender": "Unisex",
            "productType": "Camiseta",
            "productName": "Polo de cuello redondo",
            "productTheme": "Heartstopper",
            "alterName": "T-shirt",
            "description": "Es la historia de amor entre dos chicos, uno de ellos declaradamente homosexual y el otro en vías de autodescubrimiento, nos recordará que hay primeros amores frágiles como el cristal y memorables como el diamante.",
            "productMaterial": "100% algodón peinado / Jersey 30/1. Talla completa (unisex) #3005.",
            "productMeasure": "Talla XS: Largo 68cm x Ancho 47cm . Talla S: Largo 70cm x Ancho 49cm . Talla M: Largo 71cm x Ancho 51cm . Talla L: Largo 72cm x Ancho 53cm . Talla XL: Largo 73.5cm x Ancho 56cm.",
            "color": "White",
            "size": "XS, S, M, L, XL, XXL",
            "price": "49",
            "currency": "soles",
            "imageUrl": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-before28-Blanco.jpg?alt=media&token=0970d4f8-1ed9-49ae-8f8c-477df8a841ab",
            "imageExtra1Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-man28-Blanco.jpg?alt=media&token=96ab1b7b-cd5c-4bf3-98ed-59797867737c",
            "imageExtra2Url": "https://firebasestorage.googleapis.com/v0/b/store-demoqrata.appspot.com/o/resources%2Fproducts%2Fcollection2%2FPolo-woman28-Blanco.jpg?alt=media&token=d6f1cdb1-4d61-4186-bd3b-bed749fa4c9c",
            "character": "Felix",
            "scene": "12",
            "timestamp": "600",
            "hotsale": "false",
            "characterUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            "brandUrl": "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fbrands-logo%2F14-zara.png?alt=media&token=594afba5-77fe-4248-b6f2-a72ae0f5e33e"
        }
]