import React, {useContext} from 'react';
import { useStateValue } from '../../StateProvider';
import { SumaContext } from '../../context/SumaContext';
import "firebase/compat/firestore";
import 'react-tabs/style/react-tabs.css';


function SubtotalAnexo() {
    const [sumatotal, setSumatotal] = useContext(SumaContext);
    
    const [{carrito}, dispatch] = useStateValue();
    
    let sumaparcial=0;
    let sumasemitotal=0;
    var i;

    for (i=0; i<carrito.length; i++) {
        sumaparcial = (carrito[i]?.quantity*carrito[i]?.price);
        sumasemitotal = (sumasemitotal + sumaparcial);
    }

    var sumapretotal = sumasemitotal.toFixed(2);
    setSumatotal(sumapretotal);

    if (carrito.length == 0) {
        setSumatotal(0);
    }

    return (
        <div></div>
    )
}

export default SubtotalAnexo;