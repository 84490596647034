import React, { useEffect, useState, Suspense, lazy } from 'react';
import './App.css';
import "./fonts/IntroDemo-BlackCapsInline.otf";
import { SumaContext } from './context/SumaContext';
/* import HomeScreen from './screens/HomeScreen'; */
import LoginScreen from './screens/landing/LoginScreen'; 
import So1Screen from './screens/landing/So1Screen';
import ProductScreen from './screens/ProductScreen';
import CheckoutScreen from './screens/CheckoutGuestScreen';
import MyProductlistScreen from './screens/MyProductlistScreen';
/* import Categories from './screens/CategoriesScreen';
import Creators from './screens/CreatorScreen';
import Originals from './screens/OriginalsScreen';
import Watch from './screens/WatchScreen';
import Search from './screens/SearchScreen';
import NoSearch from './screens/NoSearchScreen';
import ProfileScreen from './screens/ProfileScreen';
import PersonalizeScreen from './screens/PersonalizeScreen';
import TvScreen from './screens/TvScreen';
import PlanScreen from './screens/PlanScreen';
import PayScreen from './screens/PayScreen';
import PayDetailScreen from './screens/PayDetailScreen';
import NewUserScreen from './screens/NewUserScreen';
import SignInScreen from './screens/SignInScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen'; */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, login, selectUser } from "./features/userSlice";
import { auth } from "./firebase.js";


/* const HomeLoad = lazy(() => import('./screens/HomeScreen'))
const CategoriesLoad = lazy(() => import('./screens/CategoriesScreen'))
const SearchLoad = lazy(() => import('./screens/SearchScreen'))
const CreatorLoad = lazy(() => import('./screens/CreatorScreen'))
const OriginalsLoad = lazy(() => import('./screens/OriginalsScreen'))
const WatchLoad = lazy(() => import('./screens/WatchScreen'))
const ProfileLoad = lazy(() => import('./screens/ProfileScreen'))
const PersonalizeLoad = lazy(() => import('./screens/PersonalizeScreen')) */
const LoginLoad = lazy(() => import('./screens/landing/LoginScreen')) 
const So1Load = lazy(() => import('./screens/landing/So1Screen'))
const ProductScreenLoad = lazy(() => import('./screens/ProductScreen'))
const CheckoutScreenLoad = lazy(() => import('./screens/CheckoutGuestScreen'))
const MyProductlistLoad = lazy(() => import('./screens/MyProductlistScreen'))
/* const PlanLoad = lazy(() => import('./screens/PlanScreen'))
const PayLoad = lazy(() => import('./screens/PayScreen'))
const PayDetailLoad = lazy(() => import('./screens/PayDetailScreen'))
const SigninLoad = lazy(() => import('./screens/SignInScreen'))
const ResetLoad = lazy(() => import('./screens/ResetPasswordScreen'))
const NewUserLoad = lazy(() => import('./screens/NewUserScreen')) */

function App() {

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [sumatotal, setSumatotal] = useState(0);
  let history = useHistory();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        //Logged in
        console.log(userAuth);
        dispatch(login({
          uid: userAuth.uid,
          email: userAuth.email,
        })
        );
      } else {
        //Logged out
        dispatch(logout);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <SumaContext.Provider value={[sumatotal, setSumatotal]}>
    <div className="app" >
      <Router onUpdate={() => window.scrollTo(0, 0)}>
      {<Switch>
        
        <Route component={CheckoutScreenLoad} exact path="/checkout">
          <CheckoutScreen />
        </Route>

        <Route component={ProductScreenLoad} exact path="/product/:productId">
          <ProductScreen />
        </Route>

        <Route component={MyProductlistLoad} exact path="/listadecompras">
          <MyProductlistScreen />
        </Route>

        <Route component={So1Load} exact path="/home">
          <So1Screen />
        </Route>

        <Route component={LoginLoad} path="/">
            <LoginScreen />
        </Route>

      </Switch>}

        {/* {!user ? (
          
        <Switch>

          <Route component={So1Load} exact path="/lacopadelmundo">
            <So1Screen />
          </Route>

          <Route component={NewUserLoad} exact path="/newuser">
            <NewUserScreen />
          </Route>

          <Route component={ResetLoad} exact path="/reset">
            <ResetPasswordScreen />
          </Route>

          <Route component={SigninLoad} exact path="/signin">
            <SignInScreen />
          </Route>
          
          <Route component={PayDetailLoad} exact path="/paydetail">
            <PayDetailScreen />
          </Route>

          <Route component={PayLoad} exact path="/payment">
            <PayScreen />
          </Route>

          <Route component={PlanLoad} exact path="/signup">
            <PlanScreen />
          </Route>

          <Route component={LoginLoad} path="/">
            <LoginScreen />
          </Route>

          <Route exact path="/home">
            {user ? <Redirect to="/home" /> : <LoginScreen />}
          </Route >

        </Switch>

        ) : (
          <Suspense fallback={<h1>Cargando...</h1>}>
            <Switch>

            <Route exact path="/">
              {<Redirect to="/home" />}
            </Route>

            <Route component={HomeLoad} path="/home">
              <HomeScreen correoUsuario={user.email} />
            </Route>

            <Route component={SearchLoad} path="/search/:searchTerm">
              <Search />
            </Route>
            <Route path="/search">
              <NoSearch />
            </Route>
            <Route component={CategoriesLoad} path="/categories">
              <Categories />
            </Route>
            <Route component={CreatorLoad} path="/creators/:creatorId" exact>
              <Creators />
            </Route>
            <Route component={OriginalsLoad} path="/originals/:originalId" exact>
              <Originals />
            </Route>
            <Route component={WatchLoad} path="/watch/:watchId" exact>
              <Watch />
            </Route>
            <Route component={ProfileLoad} path="/profile" exact>
              <ProfileScreen />
            </Route>
                        
            <Route component={PersonalizeLoad} path="/personalize" exact>
              <PersonalizeScreen />
            </Route>
            <Route path="/tv">
              <TvScreen />
            </Route>
            </Switch>
          </Suspense>
        )} */}

      </Router>
    </div>
    </SumaContext.Provider>
  );
}

export default App;