import React, { useState, useEffect, useContext } from "react";
import { useStateValue } from "../StateProvider";
import { SumaContext } from '../context/SumaContext';
import "./Nav.css";
import { getAuth } from "firebase/auth";
import  firebaseApp from "../firebase";
import 'firebase/compat/storage';
import { Link, useHistory } from "react-router-dom";
import logo from "../assets/Unishoper.svg";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import LiveTvSharpIcon from "@material-ui/icons/LiveTvSharp";
/* import ArrowDropDownSharpIcon from "@material-ui/icons/ArrowDropDownSharp";
import  { DropdownMenu, NavItem }  from "./DropdownMenu"; */
import { Badge } from "@material-ui/core";
import { ShoppingCartOutlined } from "@material-ui/icons";
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
      secondary: {
        main: 'rgba(165, 55, 253)'
      }
    }
  });

function Nav() {
    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    const db = firebaseApp.firestore();

    const [{carrito}, dispatch] = useStateValue();
    const [sumatotal, setSumatotal] = useContext(SumaContext);

    const [show, handleShow] = useState(false);
    const [ datauser, setDatauser ] = useState();
    const [ imguser, setImguser ] = useState("");

    if (carrito.length === 0) {
        setSumatotal((0).toFixed(2));
    }

    const history = useHistory();

    const handleHome = (e) => {
        e.preventDefault();
        history.push('/home');
    };

    const transitionNavBar = () => {
        if (window.scrollY > 100) {
            handleShow(true);
        } else {
            handleShow(false);
        }
    };

    useEffect (() => {
        window.addEventListener("scroll", transitionNavBar);
        return () => window.removeEventListener("scroll", transitionNavBar);
    }, []);

    const getData = async () => {
        try {
          const documentSnapshot = await db
            .collection('users')
            .doc(user.uid)
            .get();
    
          const userData = documentSnapshot.data();
          setDatauser(userData);
        } catch(err) {
            console.log(err);
        }
      };
      useEffect(() => {
        getData();
      }, []);

      let img = document.getElementById('img_nav');

      auth.onAuthStateChanged(userStatus => {
        if (userStatus) {
            var storage = firebaseApp.storage();
            var storageRef = storage.ref();
            storageRef.child('users/' + user.uid + '/profile.jpg').getDownloadURL()
            .then(imgUrl_nav => {
                setImguser(imgUrl_nav);
            }).catch(error => {
                console.log(error.message);
        })
        }
    })

    return (
        <MuiThemeProvider theme={theme}>
        <div className={`nav ${show && "nav__black"}`}>
            <div className="nav__contents">
                <Link to="/home" style={{ textDecoration: 'none', margin:"0", alignItems:"center", display: "flex" }}>
                <div className='nav__logo'>
                    <img 
                    onClick={handleHome}
                    src={logo}
                    alt="Unishoper" title="made for fun"
                    />
                </div> 
                </Link>

                <div className="nav__profile">
                    
                <div className="nav__icons">
                    
                    <Link to="/home" style={{ textDecoration: 'none', color:"white" }}>
                    <div className="nav__icon"
                    >
                    <HomeSharpIcon />
                    <p>Inicio</p>
                    </div>
                    </Link>

                    {/* <Link to="/tv" style={{ textDecoration: 'none', color:"white" }}>
                    <div className="nav__icon">
                        <LiveTvSharpIcon />
                        <p>Modo TV</p>
                    </div>
                    </Link> */}

                    <div className="so1screen__icons">
                    <p className="" style={{ color:"white" }} >S/ {sumatotal}</p>

                    <Link to="/listadecompras" className="">
                    <Badge badgeContent={carrito?.length} color="secondary">
                    <ShoppingCartOutlined
                    style={{ color:"white" }} />
                    </Badge>
                    </Link>
                    </div>

                </div>
                
                {/* <div className="nav__profile__block">
                    <p>Hola {datauser && datauser.firstname}</p>
                    <img 
                    className='nav__avatar'
                    src= {imguser} 
                    style={{borderRadius:"50%",}} 
                    id="img_nav"
                    alt="" 
                    onError={(e)=>{e.target.onerror = null; e.target.src="https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2FDMQbrand_square.jpg?alt=media&token=1eb20f9a-156b-4f1a-9cb9-1e56a5605b12"}}
                    />
                   
                    <NavItem icon={<ArrowDropDownSharpIcon />} className="nav__profile__dropdown">
                        <DropdownMenu></DropdownMenu>
                    </NavItem>
                </div> */}
                </div>
            </div>
        </div>
        </MuiThemeProvider>
    )
}

export default Nav;
