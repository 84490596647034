import React, {useState} from 'react';
import { Link, useParams } from "react-router-dom";
import { useStateValue } from '../StateProvider';
import { ArrowBack } from "@material-ui/icons";
import Nav from "../components/Nav";
/* import Contact from "../components/Footer/Contact"; */
/* import NavBottom from "../components/Body/NavBottom"; */
import { products } from "../assets/example.js";
import Product from '../components/Product';
import Copyright from "../components/Footer/Copyright";
import GoToTop from "../GoToTop";
import "./ProductScreen.css";
import SubtotalAnexo from "../components/ShoppingCart/SubtotalAnexo";
import Head from "next/head";
import {Helmet} from "react-helmet";
/* import SocialLinks from '../components/Footer/SocialLinks'; */

const ProductScreen = ({ id, productCod, imageUrl, image, productName, category, /* brand, */ productType, productTheme, description, color, price, character }) => {

  const [{carrito}, dispatch] = useStateValue();
  const { productId } = useParams();
  const a = products.filter((item, key) => 
  (item.productCod).includes(productId));
  const b = a[0]?.category;
  const [quantity, setQuantity] = useState(1);
  const [obs, setObs] = useState("");

  /* console.log(a);
  console.log(b); */

  const addToCarrito = (e) => {
    dispatch({
      type: 'ADD_TO_CARRITO',
      item: {
        id: a[0]?.id,
        productCod: a[0]?.productCod,
        productName: a[0]?.productName,
        category: a[0]?.category,
        brandUrl: a[0]?.brandUrl,
        productType: a[0]?.productType,
        productTheme: a[0]?.productTheme,
        description: a[0]?.description,
        color: a[0]?.color,
        price: a[0]?.price,
        character:a[0]?.character,
        image:a[0]?.imageUrl,
        quantity: quantity,
        obs: obs,
      }
    })
  }

  const handleDecrement = () => {
    if (quantity >= 0.5 && a[0]?.presentation === "Granel") {
      setQuantity(quantity-0.25)
    } else if (quantity >= 2 && a[0]?.presentation !== "Granel") {
      setQuantity(quantity-1)
    }
  }

  const handleIncrement = () => {
    if (a[0]?.presentation === "Granel") {
      setQuantity(quantity+0.25)
    } else if (a[0]?.presentation !== "Granel") {
      setQuantity(quantity+1)
    }
  }

  return (
    <main>
      <Head>
        <title>{a[0]?.productName} </title>
        <meta 
          name='description'
          content=""
        />
      </Head>

      <Nav />
      
      <div className="headsign">
      <Link to="/home" style={{ color:"white", textDecoration: 'none'}} >
        <div className="continueshopping" >
          <ArrowBack/>
          <p>Regresar</p>
        </div>
      </Link>
      {/* <p className="headsign__idlist">id: list646</p> */}
      </div>

      <SubtotalAnexo/>

      <div className="productScreen">
      {products
        .filter((item, key) => 
        (item.productCod).includes(productId))
        .map((item) => (
          <div className='productScreen__container' key={item.id} >

          <Helmet>
            <title>{item.productName} </title>
            <meta 
              name='description'
              content=""
            />
          </Helmet>

          <div className='productScreen__container__box'>

          <img className='productScreen__container__boximg' src={item.imageUrl} alt={item.productName} />

          <div className='productScreen__info'>

          <div className='productScreen__info__div__brandimg'>
            <img
              src={item.brandUrl}
              alt=""
              className='productScreen__info__brandimg'
            />
          </div>

          <div>
            <p className='productScreen__info__cod'>Código: {item.productCod} </p>

            <h1 className='productScreen__info__productname'>{item.productName} <span>  </span> {/* &bull; */} </h1>

            {/* <p className='productScreen__info__brand'>{item.brand} </p> */}

            {/* <p className='productScreen__info__presentation'><span>Presentación:</span> {item.presentation} </p> */}

            <p className='productScreen__info__category'>Categoría: {item.category}</p>
          
            <p
            style={{ textDecoration: "line-through", fontSize:"1rem" }}
            >Antes: S/ {(item.price*1.12).toFixed(2)}</p>

            <p className='productScreen__info__price'>
              <span className='productScreen__info__price__currency'>Ahora:</span> S/ {item.price} <span className='productScreen__info__price__currency'>soles.</span></p>

            

          <div className='productScreen__carrito' >
            <div className='productScreen__carrito__buttons'>
              <button type="button" onClick={() => handleDecrement(item.id)}>-</button>

              <p >{quantity}</p>

              <button type="button" onClick={() => handleIncrement(item.id)}>+</button>

            </div>

            <div className='productScreen__carrito__comment'>
            <textarea value={obs} 
              onChange={(e) => setObs(e.target.value)} id="obs" type="text" placeholder="Indícanos aquí alguna necesidad de empaque, tratamiento o selección del producto." rows="5" cols="33">
            </textarea>
            </div>

            <button className='productScreen__carrito__add' onClick={addToCarrito} >AGREGA AL CARRITO</button>
          </div>

          {/* <p className='productScreen__prom'>Pide durante 9 semanas seguidas y nosotros pagaremos el 50% de tu siguiente pedido.</p> */}
        </div>
        </div>
        </div>

        {/* <div className='productScreen__recommended'>
        <h2>Otros productos similares:</h2>

        </div> */}

        </div>
        ))}
      </div>
      {/* <SocialLinks/> */}
      {/* <Contact/> */}
      <Copyright/>
      <GoToTop/>
    </main>
  )
}

export default ProductScreen;