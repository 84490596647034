import React from 'react';
import { useHistory } from "react-router-dom";
import "./LoginScreen.css";
/* import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; */
import logo from "../../assets/Unishoper.svg";
import devices from "../../assets/devicesWhite.svg";
/* import { ArrowDownwardSharp } from '@material-ui/icons'; */
/* import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; */
/* import FooterLinks from "../../components/Footer/FooterLinks";
import Social from "../../components/Footer/SocialLinks"; */
import Copyright from "../../components/Footer/Copyright";
import { Helmet } from "react-helmet-async";
/* import { Link } from "react-router-dom"; */
import GoToTop from '../../GoToTop';
import feat1 from "../../assets/unishoper-feat1.webm";
import feat2 from "../../assets/unishoper-feat2.webm";
import feat3 from "../../assets/unishoper-feat3.webp";
import estreno from "../../assets/Banner1.jpeg";
import bajolared from "../../assets/bajolared.jpg";


function LoginScreen() {

    const history = useHistory();

    /* const signIn = (e) => {
        e.preventDefault();
        history.push("/signup");
    }; */

    const handleHome = (e) => {
        e.preventDefault();
        history.push("/home");
    };

    return (
        <>
            <Helmet>

                <title>Unishoper: compra en línea mientras te entretienes con tu serie o película favorita.</title>

                <meta
                    name="title"
                    content="Unishoper: compra en línea mientras te entretienes con tu serie o película favorita."
                />

                <meta
                    name="description"
                    content="Plataforma de compra en línea que te permite interactuar con las prendas y accesorios que más te gustan mientras te entretienes con tu serie o película favorita."
                />

                <link
                    rel='canonical'
                    href='/'
                />

                <meta
                    name='image'
                    content="https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fcover%2FUnishoper-Cover02.webp?alt=media&token=315ebd57-36fe-4043-ad97-aaa11e5e4f34"
                />

            </Helmet>

            <div className="loginScreen">

                {/* <div className="loginScreen__box__signup">
                     <div className="loginScreen__signup">
                        <form >
                            <button>
                                <Link to="/signin" style={{ textDecoration: 'none', color: "white" }}>Inicia sesión
                                </Link>
                            </button>
                            <button>
                                <Link to="/signup" style={{ textDecoration: 'none', color: "white" }}>Suscríbete
                                </Link>
                            </button>
                        </form>
                    </div> 
                </div> */}

                <div className="loginScreen__box__divcover"
                    style={{
                        position: "relative",
                        zIndex: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        margin:"0",
                        padding:"0",
                        bottom: "0",
                        backgroundImage: `url(
                            "https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fcover%2FUnishoper-Cover01.webp?alt=media&token=252367fa-a175-48e5-a752-3faee89b67c0"
                        )`,
                    }} >

                    <div className="loginScreen__cover__main">

                    <div className="loginScreen__cover__contents">

                        <img
                        src={logo}
                        className="loginScreen__logo"
                        alt="logo Unishoper" title="made for fun"
                        />
                        <h1>
                            DIVIÉRTETE.
                            SUEÑA.
                            <span>COMPRA LO QUE USAN LOS PERSONAJES DE TUS SERIES FAVORITAS.</span>
                        </h1>
                        <p>Tu plataforma de comercio electrónico integrada a la narrativa cinematográfica. {/* de tus series y películas favoritas. al contenido de entretenimiento audiovisual por streaming. */}</p>
                        <button
                        onClick={handleHome}
                        >ENTRAR <img src={devices} alt='' /></button>
                        
                    </div>

                    <div className="loginScreen__cover__img">
                        <img
                        src='https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fcover%2FUnishoper-Cover02.webp?alt=media&token=315ebd57-36fe-4043-ad97-aaa11e5e4f34'
                        alt=''
                        />
                    </div>

                    </div>
                </div>

                <div className="loginScreen__features">
                    <div className="loginScreen__features__title">
                        <h2>¿CÓMO FUNCIONA?</h2>
                        <p>Imagina que Netflix se casara con Amazon.</p>
                        {/* <p>Tus series favoritas + Los productos que amas.</p> */}
                    </div>
                    <div className="loginScreen__featvideo">
                        <h3><span>1.</span> Mira tu serie favorita, identifícate con los personajes y disfruta la historia.</h3>
                        <video
                            autoPlay loop muted
                            style={{ maxWidth: "350px",
                            height: "100%" }}
                        >
                        <source src={feat2} type='video/webm' />
                        </video>
                    </div>

                    <div className="loginScreen__featvideo">
                        <h3><span>2.</span> Elige las prendas y accesorios que usan los personajes en cada escena.</h3>
                        <video
                            autoPlay loop muted
                            style={{ maxWidth: "350px",
                            height: "100%" }}
                        >
                        <source src={feat1} type='video/webm' />
                        </video>
                    </div>

                    <div className="loginScreen__featimg">
                        <h3><span>3.</span> Compra lo que más te guste o añádelos a tu lista de deseos.</h3>
                        <img
                            src={feat3}
                            width="200px"
                            height="auto"
                        />
                    </div>
                </div>

                <div
                className="so1screen__estrenoserie"
                style={{
                    position: "relative",
                    zIndex: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    backgroundImage: `url(
                        ${estreno}
                    )`,
                }}
                >
                <div className="so1screen__estrenoserie__left">
                    <p className="so1screen__estrenoserie__left__soon">EN ESTRENO</p>
                    <img
                    src={bajolared}
                    alt=""
                    />
                    <p className="so1screen__estrenoserie__left__genres">Género: Thriller, Comedia</p>
                    <p className="so1screen__estrenoserie__left__synopsis">Un grupo de jóvenes atrapados en una cadena de favores en internet se unen para desenmascarar a su misterioso organizador, El Conseguidor, un enigmático individuo que oculta su identidad detrás de una máscara virtual. Solo se puede hablar con él a través de videoconferencia.</p>

                    <div className="so1screen__estrenoserie__video">
                        <button
                        onClick={handleHome}
                        >Ver ahora</button>
                    </div>

                </div>

                <div className="so1screen__estrenoserie__right">
                </div>
                
            </div>

                {/* <Social /> */}
                {/* <FooterLinks /> */}
                <Copyright />
                <GoToTop />
            </div>
        </>
    )
}

export default LoginScreen;