import React, { useContext, useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import  firebaseApp from "../firebase";
import "firebase/compat/firestore";
import { serverTimestamp, doc, getDoc } from "firebase/firestore";
import "./CheckoutGuestScreen.css";
import { useStateValue } from '../StateProvider';
import { SumaContext } from '../context/SumaContext';
import Nav from '../components/Nav';
/* import Contact from '../components/Footer/Contact'; */
import Copyright from '../components/Footer/Copyright';
import PaypalBoxGuest from "../components/PaypalBoxGuest";
import GoToTop from '../GoToTop';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { PhoneAndroidSharp, RedoSharp } from "@material-ui/icons/";
import {Helmet} from "react-helmet";
import SocialLinks from "../components/Footer/SocialLinks";


function CheckoutGuestScreen() {

    const [sumatotal, setSumatotal] = useContext(SumaContext);
    const [sumafinal, setSumafinal] = useState();
    const db = firebaseApp.firestore();
    const [{carrito}, dispatch] = useStateValue();
    const [nombre, setNombre] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [direccion, setDireccion] = useState("");
    const [ciudad, setCiudad] = useState("");
    /* const [pais, setPais] = useState(""); */
    const [referencia, setReferencia] = useState("");
    const [horario, setHorario] = useState("Compra unica");
    const [nombrelista, setNombrelista] = useState("");
    const [medio, setMedio] = useState("");
    const [operacion, setOperacion] = useState("");
    const [fecha, setFecha] = useState("");

    const [loader, setLoader] = useState(false);

    const history = useHistory();

    const handleHome = (e) => {
        e.preventDefault();
        history.push("/home");
      };
    
    useEffect(() => {
        if (sumatotal >= 100.00) {
            setSumafinal((parseFloat(sumatotal)).toFixed(2))
            
        } else {
            setSumafinal((parseFloat(sumatotal) + 5.00).toFixed(2))
        }
    }, []);


    const handleSubmitPedido = async (e) => {
        e.preventDefault();
        setLoader(true);
    
        await db.collection("pedidos_guest")
          .add({
            uid: "guest",
            nombre: nombre,
            apellidos: apellidos,
            telefono: telefono,
            email: email,
            direccion: direccion,
            ciudad: ciudad,
            pais: "Perú"/* pais */,
            referencia: referencia,
            horario: horario,
            nombrelista: nombrelista,
            medio: medio,
            operacion: operacion,
            fecha: Date.now(),
            carrito: carrito,
            sumatotal: sumatotal,
            sumafinal: sumafinal,
            timestamp: serverTimestamp(),
            status: "Pendiente",
            id: (email.substring(0, 3))+Date.now(),
          })
          .then(() => {
            setLoader(false);

            alert(JSON.stringify(nombre) + " hemos recibido tus detalles de pago y ya estamos preparando tu box de Snakia. En unos minutos recibirás un correo de confirmación. Tu pedido llegará el " + JSON.stringify(horario) + " a " + JSON.stringify(direccion));
            
            history.push("/gracias");
            /* navigate("/pedidos"); */
            /* window.location.href = 'https://www.almerqao.web.app/thanks'; */

          })
          .catch((error) => {
            alert(error.message);
            setLoader(false);
          });
    };

  return (
    <div>
        <Helmet>
        <title>Unishoper: compra en línea mientras te entretienes con tu serie o película favorita.</title>
        <meta 
          name='description'
          content="Plataforma de compra en línea que te permite interactuar con las prendas y accesorios que más te gustan mientras te entretienes con tu serie o película favorita."
        />
        </Helmet>
        
        <Nav/>
        
        <div className="checkoutguestScreen">

            <div className="checkoutguestScreen__resumen">
                <h2 className="checkoutguestScreen__resumen__title"><span>1er. paso</span>Resumen</h2>

                <div className="checkoutguestScreen__resumen__inline">
                    <p className="checkoutguestScreen__resumen__t1">Compras</p>
                    <p className="checkoutguestScreen__resumen__t2">S/ {sumatotal}</p>
                </div>

                {sumatotal >= 100.00 
                ?
                <div className="checkoutguestScreen__resumen__math">
                    <div className="checkoutguestScreen__resumen__inline">
                        <p className="checkoutguestScreen__resumen__t1">Delivery gratis</p>
                        <p className="checkoutguestScreen__resumen__t2">S/ 0.00</p>
                    </div>
                    <div className="checkoutguestScreen__resumen__inline">
                        <p className="checkoutguestScreen__resumen__t1">Total a pagar </p>
                        <p 
                        className="checkoutguestScreen__resumen__t2"
                        style={{ fontWeight:"700" }}
                        >S/ {sumafinal} </p>
                    </div>
                </div> 
                :
                <div className="checkoutguestScreen__resumen__math">
                    <div className="checkoutguestScreen__resumen__inline">
                        <p className="checkoutguestScreen__resumen__t1">Delivery</p>
                        <p className="checkoutguestScreen__resumen__t2">S/ 5.00</p>
                    </div>
                    
                    <div className="checkoutguestScreen__resumen__inline">
                        <p className="checkoutguestScreen__resumen__t1">Total a pagar </p>
                        <p
                        className="checkoutguestScreen__resumen__t2"
                        style={{ fontWeight:"700" }}
                        >S/ {sumafinal} </p>
                    </div>
                    
                </div>
                
                }
            </div>

            <div className="checkoutguestScreen__continueshopping">
                <p>Delivery gratis en Lima metropolitana por compras mayores a S/ 100 soles.</p>
                <button
                onClick={handleHome}
                >Seguir comprando <RedoSharp/> </button>
            </div>

            <div className="checkoutguestScreen__tabs">
            <h2 className="checkoutguestScreen__title"><span>2do. paso</span>Opciones de pago</h2>

            <Tabs className="" defaultIndex={0}>
                <TabList style={{ textAlign:"center" }}>
                    <Tab style={{ color:"white" }}>Tarjeta</Tab>
                    {/* <Tab style={{ color:"#111" }}>Yape</Tab>
                    <Tab style={{ color:"#111" }}>Transferencia</Tab> */}
                </TabList>

                <TabPanel>
                    <PaypalBoxGuest />
                </TabPanel>
                {/* <TabPanel>
                    <div className="checkoutguestScreen__yape">
                        <img src="https://firebasestorage.googleapis.com/v0/b/almerqao.appspot.com/o/resources%2Fextras%2FYAPE-Sayo.png?alt=media&token=769bfa40-25c0-4f87-9959-ceb3c6f5e200" alt="Yapea al: +51 942 137 078"/>
                        <p><PhoneAndroidSharp style={{ paddingRight:"5px" }} />942 137 078</p>
                        <span>Sayandro Kaliski Chacón</span>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="checkoutguestScreen__transferencia">
                    <img src="https://firebasestorage.googleapis.com/v0/b/almerqao.appspot.com/o/resources%2Fextras%2Fbcp.svg?alt=media&token=a264279d-838e-4bcf-aee2-15929729cb0d" style={{ width:"120px", paddingTop:"10px" }} alt="BCP" />
                    <p style={{paddingTop:"10px", fontSize:"1rem", color:"#111" }}>Banco de Crédito del Perú BCP</p>
                    <p style={{ color:"#111" }}>Cuenta de ahorros en soles</p>
                    <p style={{fontSize:"1.2rem", padding:"10px 0", color:"#111" }} >N° 194-97709184-0-42 / CCI N° 00219419770918404299</p>
                    <p style={{fontSize:"1rem", color:"#111" }}>SWIFT code: BCPLPEPL</p>
                    <p style={{ color:"#111" }}>Titular: INSIGHTER DEL PERU S.A.C.</p>
                    <p style={{ color:"#111" }}>RUC N° 20536307401</p>
                    </div>
                </TabPanel> */}
                
            </Tabs>
            </div>
            </div>


            <div className="checkoutguestScreen__data">
                <h2 className="checkoutguestScreen__data__title"><span>3er. paso</span>Formulario de datos de pago</h2>
                <form className="checkoutguestScreen__data__form" >

                <ul>
                    <li>
                    <input type="text" placeholder='Nombre' value={nombre} onChange={(e) => setNombre(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Apellidos' value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='00 + Teléfono' value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                    </li>

                    <li>
                    <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Dirección de entrega.' value={direccion} onChange={(e) => setDireccion(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Ciudad' value={ciudad} onChange={(e) => setCiudad(e.target.value)} />
                    </li>
                    
                    <li>
                    <input type="text" placeholder='Referencia para ubicar la dirección.' value={referencia} onChange={(e) => setReferencia(e.target.value)} />
                    </li>

                    <li>
                    <div className="checkoutguestScreen__data__form__select">
                        <p>Elija una fecha y rango de horario para recibir el pedido &#8595;</p>
                        <select value={horario} onChange={(e) => setHorario(e.target.value)}>
                            <option value="Compra unica" selected>Compra única</option>

                            <option value="Día 7 de cada mes entre las 08:00-13:00 horas.">Día 7 de cada mes entre las 8:00-13:00 horas.</option>

                            <option value="Día 7 de cada mes entre las 14:00-20:00 horas.">Día 7 de cada mes entre las 14:00-20:00 horas.</option>

                            <option value="Día 14 de cada mes entre las 08:00-13:00 horas.">Día 14 de cada mes entre las 8:00-13:00 horas.</option>

                            <option value="Día 14 de cada mes entre las 14:00-20:00 horas.">Día 14 de cada mes entre las 14:00-20:00 horas.</option>

                            <option value="Día 21 de cada mes entre las 08:00-13:00 horas.">Día 21 de cada mes entre las 8:00-13:00 horas.</option>

                            <option value="Día 21 de cada mes entre las 14:00-20:00 horas.">Día 21 de cada mes entre las 14:00-20:00 horas.</option>

                            <option value="Día 28 de cada mes entre las 08:00-13:00 horas.">Día 28 de cada mes entre las 8:00-13:00 horas.</option>

                            <option value="Día 28 de cada mes entre las 14:00-20:00 horas.">Día 28 de cada mes entre las 14:00-20:00 horas.</option>
                            
                        </select>
                    </div>
                    </li>

                    <li>
                    <input type="text" placeholder='Nombre de lista de compras.' value={nombrelista} onChange={(e) => setNombrelista(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='¿Qué medio de pago utilizó?' value={medio} onChange={(e) => setMedio(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Nro de operación de pago.' value={operacion} onChange={(e) => setOperacion(e.target.value)} />
                    </li>

                    <li>
                    <input type="text" placeholder='Fecha y hora de pago.' value={fecha} onChange={(e) => setFecha(e.target.value)} />
                    </li>
                </ul>
                </form>
            </div>
            
            <div className="checkoutguestScreen__paybutton">
            <button 
                type='submit' 
                onClick={handleSubmitPedido} >Enviar detalles de pago
            </button>
            </div>

        {/* <SocialLinks/> */}
        {/* <Contact/> */}
        <Copyright/>
        <GoToTop/>
    </div>
  )
}

export default CheckoutGuestScreen;