import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
/* import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; */
import { /* useParams, Link, */ useHistory } from "react-router-dom";
import "./So1Screen.css";
import herederos from "../../assets/Herederos.png";
import video1 from "../../assets/unishoper-seriedemo1-1.mp4";
import video2 from "../../assets/unishoper-seriedemo1-2.mp4";
import video3 from "../../assets/unishoper-seriedemo1-3.mp4";
import video4 from "../../assets/unishoper-seriedemo1-4.mp4";
import banner1 from "../../assets/Banner2.webp";
import Nav from "../../components/Nav";
import Copyright from "../../components/Footer/Copyright";
import GoToTop from '../../GoToTop';
import { products } from "../../assets/example.js";
import { useStateValue } from '../../StateProvider';
import SubtotalAnexo from "../../components/ShoppingCart/SubtotalAnexo";
import { TimelapseSharp, TheatersSharp, AddCircleOutlineSharp, EmojiEmotionsSharp, SwapHorizRounded } from "@material-ui/icons";
import { Link as ScrollVideo } from "react-scroll";


function So1Screen() {

    const [{ carrito }, dispatch] = useStateValue();
    const [codigo, setCodigo] = useState("");
    var a = [];
    const [quantity, setQuantity] = useState(1);
    const history = useHistory();

    const [ progress, setProgress ] = useState("");
    const [ arrayprod, setArrayprod ] = useState();
    const [selected, setSelected] = useState(video2);
    const [thumbnail, setThumbnail] = useState("https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2FThumb2.jpg?alt=media&token=05fda96d-8c6d-4fbd-92e8-0d6b5d01f1ad");
    const [id, setId] = useState(2);

    const Collapsible = () => {
        const [open, setOPen] = useState(false);

        const toggle = () => {
            setOPen(!open);
          };

        return (
          <div>
            <button 
            style={{ padding:"20px", borderRadius:"20px", border:"none", fontSize: "1rem", backgroundColor:"var(--unishoper-two)", color:"white", justifyContent:"center", margin:"20px 0", width:"100%", textAlign:"center", display:"flex" }}
            onClick={toggle}>Ver la Sinopsis de la Serie <AddCircleOutlineSharp width="10px" style={{ padding:"0 10px" }} /></button>
            {open && (
                <div className="toggle">
                    <h4>Un grupo de jóvenes atrapados en una cadena de favores en internet se unen para desenmascarar a su misterioso organizador, El Conseguidor, un enigmático individuo que oculta su identidad detrás de una máscara virtual. Solo se puede hablar con él a través de videoconferencia.</h4>
                </div>
            )}
          </div>
        );
    };


    const handleImg = (e) => {
    e.preventDefault();
    history.push(`/product/${e.target.id}`);
    }

    const addToCarrito = (e) => {
        setCodigo(e.target.id);

        /* console.log("codigo:", codigo); */

        a = products.filter((item, key) =>
        (item.productCod).includes(e.target.id));

        /* console.log("a:", a); */
        /* console.log("aprecio:", a[0]?.price);
        console.log(typeof a[0]?.price); */

        dispatch({
        type: 'ADD_TO_CARRITO',
        item: {
            id: a[0]?.id,
            productCod: a[0]?.productCod,
            productName: a[0]?.productName,
            category: a[0]?.category,
            brandUrl: a[0]?.brandUrl,
            productType: a[0]?.productType,
            productTheme: a[0]?.productTheme,
            description: a[0]?.description,
            color: a[0]?.color,
            price: a[0]?.price,
            character:a[0]?.character,
            image:a[0]?.imageUrl,
            quantity: quantity,
        }
        })

        /* SubtotalAnexo(); */
    }

    const personajes = [
        {
            id: 1,
            name: "Luciana",
            imgUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch3.png?alt=media&token=f140ae3a-b2d1-4208-a4d1-a2d36b4f8a49",
            description: "Inteligente y Audaz"
        },
        {
            id: 2,
            name: "Jorge",
            imgUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch2.png?alt=media&token=f1818664-8b04-443a-a209-147f664fad7c",
            description: "Geek y Rebelde"
        },
        {
            id: 3,
            name: "Esmeralda",
            imgUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch4.png?alt=media&token=8230dd5e-f35f-41d5-8dbc-be293df4f2ff",
            description: "Amable y Buena amiga"
        },
        {
            id: 4,
            name: "Joaquin",
            imgUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch1.png?alt=media&token=939aa02c-0a6c-4d63-89e7-0fd329ffb7d4",
            description: "Peligroso y Seductor"
        },
        {
            id: 5,
            name: "Felix",
            imgUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2Fch5.png?alt=media&token=42218bed-0ac6-49b4-8cc8-11c07e2e6294",
            description: "El novio perfecto"
        }
    ]

    const episodios = [
        {
            id: 1,
            title: "Episodio #1",
            epUrl:video1,
            duration:"00:12:04",
            thumbUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2FThumb1.jpg?alt=media&token=6e05d616-641a-46af-b462-bf56578c36b2"
        },
        {
            id: 2,
            title: "Episodio #2",
            epUrl:video2,
            duration:"00:11:08",
            thumbUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2FThumb2.jpg?alt=media&token=05fda96d-8c6d-4fbd-92e8-0d6b5d01f1ad"
        },
        {
            id: 3,
            title: "Episodio #3",
            epUrl:video3,
            duration:"00:11:36",
            thumbUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2FThumb3.jpg?alt=media&token=8a9f2761-0671-463a-ac0d-f037d45a3182"
        },
        {
            id: 4,
            title: "Episodio #4",
            epUrl:video4,
            duration:"00:10:16",
            thumbUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2FThumb4.jpg?alt=media&token=2b7be2be-8ce6-4f8c-a0fe-e4f91c8d317d"
        },
        {
            id: 5,
            title: "Episodio #5",
            epUrl:video1,
            duration:"00:12:04",
            thumbUrl:"https://firebasestorage.googleapis.com/v0/b/unishoper-com.appspot.com/o/resources%2Fexample%2FThumb5.jpg?alt=media&token=7c77ece0-ada3-45d3-ae28-501078ff650f"
        }
    ]

    /* console.log("Progress:", progress); */

    useEffect(() => {
    if (progress.playedSeconds <= 49) {
        setArrayprod(
            products.filter((item, key) => 
            (item.hotsale).includes(true))
        )
    } else if (progress.playedSeconds >= 50 && progress.playedSeconds <= 99 ) {
        setArrayprod(
        products.filter((item, key) => 
        (parseInt(item.scene) === 1))
        )
    } else if (progress.playedSeconds >= 100 && progress.playedSeconds <= 149) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 2))
        )
      } else if (progress.playedSeconds >= 150 && progress.playedSeconds <= 199) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 3))
        )
      } else if (progress.playedSeconds >= 200 && progress.playedSeconds <= 249) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 4))
        )
      } else if (progress.playedSeconds >= 250 && progress.playedSeconds <= 299) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 5))
        )
      } else if (progress.playedSeconds >= 300 && progress.playedSeconds <= 349) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 6))
        )
      } else if (progress.playedSeconds >= 350 && progress.playedSeconds <= 399) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 7))
        )
      } else if (progress.playedSeconds >= 400 && progress.playedSeconds <= 449) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 8))
        )
      } else if (progress.playedSeconds >= 450 && progress.playedSeconds <= 499) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 9))
        )
      } else if (progress.playedSeconds >= 500 && progress.playedSeconds <= 549) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 10))
        )
      } else if (progress.playedSeconds >= 550 && progress.playedSeconds <= 599) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 11))
        )
      } else if (progress.playedSeconds >= 600 && progress.playedSeconds <= 649) {
        setArrayprod(
            products.filter((item, key) => 
            (parseInt(item.scene) === 12))
        )
      }
      
    }, [progress])
    
    /* console.log(arrayprod); */
    
    return (
        <div className="so1screen__container">
            <Nav/>
            
            <div className="so1screen">

                <div className="so1screen__video">
                
                <ReactPlayer
                id='video'
                className='react-player' 
                url={selected}
                light={thumbnail}
                playing="true"
                onProgress={(state) => {
                    /* console.log("State:", state); */
                    setProgress(state);
                }}
                autoplay="true" controls="true"
                width='100%'
                height='90%'
                />

                </div>

                <div className="so1screen__video__info">
                    <div className="so1screen__video__info__part1">
                    {
                        episodios
                        .filter((item, key) => 
                        item.id === id)
                        .map(({ id, title, epUrl, thumbUrl, duration }) =>
                        <div key={id}>

                            <div className="so1screen__video__info__part1__title">
                                <p><TimelapseSharp width="10px" style={{ padding:"0 10px" }}/> {duration}</p>
                                <p><TheatersSharp width="10px" style={{ padding:"0 10px" }}/> Comedia</p>
                                <p><EmojiEmotionsSharp width="10px" style={{ padding:"0 10px" }}/> 16+</p>
                            </div>

                            <div className="so1screen__video__info__part1__subtitle">
                                <h2 style={{ color:"white" }} > Bajo la red </h2>
                                
                                <h3>[{title}]</h3>
                            </div>
                            
                        </div>
                    )
                    }
                    </div>

                    <Collapsible/>

                </div>

                <div className="so1screen__img">
                <div className="so1screen__img__map">
                {
                    arrayprod
                    ?
                    (arrayprod.map(({ id, productCod, imageUrl, productTheme, description, price, character, characterUrl, brandUrl }) =>
                        <div key={id}>
                            <SubtotalAnexo />
                            <img
                                src={brandUrl}
                                alt=""
                                style={{ width:"100px", height:"auto" }}
                                className="so1screen__img__map__brands"
                            />

                            <img
                                key={id}
                                id={productCod}
                                onClick={handleImg}
                                src={imageUrl}
                                effect='blur'
                                alt={productTheme}
                                className="so1screen__img__map__img"
                                placeholderSrc={process.env.PUBLIC_URL + "/android-chrome-192x192.png"} />

                            <p
                            style={{ display:"flex", alignItems:"center", textAlign:"left",  fontSize:"1rem", opacity:"0.9" }}
                            >
                            <img
                            src={characterUrl}
                            style={{ width:"35px", alignItems:"center", paddingRight:"10px" }}
                            />
                            {character} lo usa.{/*  está usando este producto. */}
                            </p>

                            <h2 className="so1screen__img__map__title">{productTheme}</h2>

                            <h3 className="so1screen__img__map__price">S/{price}</h3>

                            <button
                            id={productCod}
                            onClick={addToCarrito}
                            >Comprar</button>
                        </div>
                    )) : (
                        <div className="so1screen__default">
                            
                            <div className="so1screen__default__title">
                            <h2>Al iniciar el capítulo verás aquí las prendas que usan tus personajes favoritos.  </h2>
                            <div className="so1screen__default__title__icon">
                            <SwapHorizRounded  sx={{ fontsize:"80px" }} />
                            </div>
                            </div>

                            <div className="so1screen__characters">
                            {
                            personajes.map(({ id, name, imgUrl, description }) =>
                                <div key={id}>
                                    <img
                                        key={id}
                                        value={id}
                                        src={imgUrl}
                                        effect='blur'
                                        alt={name}
                                        placeholderSrc={process.env.PUBLIC_URL + "/android-chrome-192x192.png"} />
                                    <h2>{name}</h2>
                                    <p>{description} </p>
                                </div>
                            )}
                            </div>
                        </div>
                        )}
                </div>
                </div>
            </div>

            <div className="so1screen__video__info2">
                    <div className="so1screen__video__info__part1">
                    {
                        episodios
                        .filter((item, key) => 
                        item.id === id)
                        .map(({ id, title, epUrl, thumbUrl, duration }) =>
                        <div key={id}>

                            <div className="so1screen__video__info__part1__title">
                                <p><TimelapseSharp width="10px" style={{ padding:"0 10px" }}/> {duration}</p>
                                <p><TheatersSharp width="10px" style={{ padding:"0 10px" }}/> Thriller, Comedia</p>
                                <p><EmojiEmotionsSharp width="10px" style={{ padding:"0 10px" }}/> 16+</p>
                            </div>

                            <div className="so1screen__video__info__part1__subtitle">
                                <h2 style={{ color:"white" }} > Bajo la red </h2>
                                
                                <h3>[{title}]</h3>
                            </div>
                            
                        </div>
                    )
                    }
                    </div>

                    <Collapsible/>

                </div>

            <div className="so1screen__videos">
                {
                    episodios.map(({ id, title, epUrl, thumbUrl }) =>
                        <div key={id}>
                            <ScrollVideo to="video" offset={-50} smooth={true} delay={300} style={{ textDecoration: 'none' }}>
                            <img
                                key={id}
                                value={id}
                                src={thumbUrl}
                                onClick={(e) => {
                                    setSelected(epUrl)
                                    setThumbnail(thumbUrl)
                                    setId(id)
                                }
                                }
                                effect='blur'
                                alt={title}
                                placeholderSrc={process.env.PUBLIC_URL + "/android-chrome-192x192.png"} />
                            </ScrollVideo>
                            <h2>{title}</h2>
                        </div>
                    )
                    }
                
                </div>

            <div
            className="so1screen__nuevaserie"
            style={{
                position: "relative",
                zIndex: "0",
                width: "auto",
                height: "100%",
                objectFit: "contain",
                backgroundImage: `url(
                    ${banner1}
                )`,
            }}
            >
                <div className="so1screen__nuevaserie__left">
                    <p className="so1screen__nuevaserie__left__soon">MUY PRONTO</p>
                    <img
                    src={herederos}
                    alt=""
                    />
                    <p className="so1screen__nuevaserie__left__genres">Género: Drama, Comedia</p>
                    <p className="so1screen__nuevaserie__left__synopsis">7 adolescentes heredarán la fortuna de sus billonarios padres y aunque esto parezca el sueño de muchos, ellos nos demostraran lo difícil que es descubrir quiénes son y qué quieren ser en la vida mientras gastan millones de dólares, se divierten sin control, se rebelan ante la sociedad y tratan de escapar de la gran responsabilidad que significa ser un heredero.</p>

                    <div className="so1screen__nuevaserie__video">
                        <iframe 
                        src={`https://www.youtube.com/embed/JBOcEWqm9x4?modestbranding=1&rel=0&showinfo=0&fs=1&cc_load_policy=1`}
                        frameborder="0"
                        allowFullScreen
                        title="Herederos"
                        width="100%"
                        height="auto" >
                        </iframe>
                    </div>

                </div>

                <div className="so1screen__nuevaserie__right">
                </div>
                
            </div>
            {/*<Social />
            <FooterLinks />*/}
            <Copyright /> 
            <GoToTop />
        </div>
    )
}
export default So1Screen;