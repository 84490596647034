import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { StateProvider } from "./StateProvider";
import reducer, { initialState } from "./reducer";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
<Provider store={store}>
<StateProvider initialState={initialState} reducer={reducer} >
    <HelmetProvider>
        <App />
    </HelmetProvider>
</StateProvider>
</Provider>
, document.getElementById("root"));