import React from 'react';
import { useStateValue } from '../../StateProvider';
import "./ProductCart.css";
import SubtotalAnexo from "../ShoppingCart/SubtotalAnexo";

function ProductCart({id, image, productCod, productName, category, productType, productTheme, description, color, price, character, brandUrl, quantity}) {
    const [{carrito}, dispatch] = useStateValue();
    const total = (price * quantity).toFixed(2);

    /* console.log("carrito:", carrito); */

    const removeItem = () => {
        dispatch({
            type: 'REMOVE_FROM_CARRITO',
            id: id
        })
    }

    return (
        <div className="productCart" >
            <SubtotalAnexo />
            <div
            style={{ display:"flex", flexDirection:"column" }}
            >
                <img
                src={brandUrl}
                alt=""
                style={{ width:"100px", height:"100%" }}
                />
                <img className="productCart__image" src={image} alt={productName} />
            </div>
            
            <div className="productCart__container">
            <div className="productCart__info">
                <p className="productCart__theme">{productName} </p>
                <p className="productCart__title">"{category}"</p>
                <p><span className="productCart__span">Código:</span>{productCod}</p>
                {/* <p><span className="productCart__span">Marca:</span>{brand}</p> */}
                <p className="productCart__price">S/{price} </p>
                <p className="productCart__price"><span className="productCart__span">Total:</span>S/{total} por {quantity} unidades </p>
            </div>
            <button onClick={removeItem} className="productCart__removebutton">Quitar del carrito.</button>
            </div>        
        </div>
    )
}

export default ProductCart;
